import React, { useEffect, useState } from "react";
import AddEditImage from "../components/AddEditImage";

const ImageInfoDesktop = () => {
  return (
    <ul>
      <li>Aspect ratio: 10:1</li>
      <li>Max image upload size: 5MB</li>
      <li>Format: JPEG, PNG</li>
    </ul>
  );
};

const ImageInfoMobile = () => {
  return (
    <ul>
      <li>Aspect ratio: 4:3</li>
      <li>Max image upload size: 5MB</li>
      <li>Format: JPEG, PNG</li>
    </ul>
  );
};

const BannerImageField = ({
  formData,
  setFormData,
  isBannerReset,
  setIsBannerReset,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (isBannerReset) {
      setFormData((prev) => ({
        ...prev,
        desktopBannerImage: { name: "", preview: "", file: "" },
        mobileBannerImage: { name: "", preview: "", file: "" },
      }));
      setIsBannerReset(false);
    }
  }, [isBannerReset]);

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const handleUploadImage = async (e, type) => {
    console.log("type", type);
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      let aspectRatio;
      let error = "";

      const previewURL = URL.createObjectURL(file);

      img.onload = () => {
        aspectRatio = img.width / img.height;
        if (file.size > 5 * 1024 * 1024) {
          error = "File size exceeds the maximum limit.";
        }
        if (type === "desktop") {
          if (aspectRatio !== 10) {
            error = "File doesn’t match specified aspect ratio.";
          }
          setFormData((prev) => ({
            ...prev,
            desktopBannerImage: {
              name: file.name,
              preview: previewURL,
              file,
              error,
            },
          }));
        } else {
          if (aspectRatio.toFixed(2) !== (4 / 3).toFixed(2)) {
            error = "File doesn’t match specified aspect ratio.";
          }
          setFormData((prev) => ({
            ...prev,
            mobileBannerImage: {
              name: file.name,
              preview: previewURL,
              file,
              error,
            },
          }));
        }
      };
    }
  };
  return (
    <div>
      <div className="tab-title-ctn">
        <div
          className={`tab-title ${activeTab === 1 && "active"}`}
          onClick={() => handleTabChange(1)}
        >
          MOBILE
        </div>
        <div
          className={`tab-title ${activeTab === 2 && "active"}`}
          onClick={() => handleTabChange(2)}
        >
          DESKTOP
        </div>
        <div className="bottom-border"></div>
      </div>
      <div className="tab-content banner-content">
        {activeTab === 1 ? (
          <AddEditImage
            data={formData.mobileBannerImage}
            type={"mobile"}
            handleUploadImage={handleUploadImage}
            id="banner-image"
            imageInfo={ImageInfoMobile}
          />
        ) : (
          <AddEditImage
            data={formData.desktopBannerImage}
            type={"desktop"}
            handleUploadImage={handleUploadImage}
            id="banner-image-desktop"
            imageInfo={ImageInfoDesktop}
          />
        )}
        <div className="default-fill">
          Default fill:{" "}
          <div
            className="fill-color"
            style={{ backgroundColor: formData.defaultHeaderBackground }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BannerImageField;
