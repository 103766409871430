export const controlCenterActionTypes = {
  WATCH_CONTROL_CENTER_MALLS: 'WATCH_CONTROL_CENTER_MALLS',
  WATCH_ADD_MALL_FEATURES: 'WATCH_ADD_MALL_FEATURES',
  WATCH_GET_CONTROL_CENTER_CHECK: 'WATCH_GET_CONTROL_CENTER_CHECK',
  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',
  REQUEST_ADD_FEATURE: 'REQUEST_ADD_FEATURE',
  COMPLETE_ADD_FEATURE: 'COMPLETE_ADD_FEATURE',
  SUCCESS_FETCH_CONTROL_MALLS: 'SUCCESS_FETCH_CONTROL_MALLS',
  SUCCESS_CONTROL_CENTER_CHECKS: 'SUCCESS_CONTROL_CENTER_CHECKS',
  SUCCESS_ADD_MALL_FEATURES: 'SUCCESS_ADD_MALL_FEATURES'
};