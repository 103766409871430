import { HTTPClient } from "./http.service";

export const dashboardService = {
  async getDashboardData(dates) {
    try {
      const result = await HTTPClient.get(
        `/dashboard/summary?startDate=${dates?.startDate}&endDate=${dates?.endDate}`
      );
      return result;
    } catch (error) {
      return error;
    }
  },
};
