import { HTTPClient } from "./http.service";
var qs = require("qs");

export const qrCodeGenerationService = {
  async addQRCampaign(data) {
    try {
      let result = HTTPClient.customRequest({
        url: `/qrCampaign`,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: data,
      });
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },

  async getQRCampaignDetails(id, data) {
    try {
      const response = await HTTPClient.get(`/qrCampaign/${id}`, data);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addMoreCodes(id, data) {
    try {
      let result = HTTPClient.post(`/qrCampaign/addQrCodes/${id}`, data);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },

  async getCampaignList(param) {
    try {
      const response = await HTTPClient.get("/qrCampaign/", param);
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getUserList(param) {
    try {
      const response = await HTTPClient.get("/qrCampaign/qrScans/", param);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateUserList(id, data) {
    try {
      const response = await HTTPClient.put(`/qrCampaign/qrScans/${id}`, data);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateQRCampaign(id, data) {
    try {
      let result = HTTPClient.put(`/qrCampaign/${id}`, data);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async activateQRCampaign(id) {
    try {
      let result = HTTPClient.put(`/qrCampaign/activate/${id}`);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deactivateQRCampaign(id) {
    try {
      let result = HTTPClient.put(`/qrCampaign/deActivate/${id}`);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async downloadZipFile(campaignId, qrLogId) {
    try {
      const response = await HTTPClient.get(
        `/qrCampaign/${campaignId}/${qrLogId}`
      );
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async retryGeneration(campaignId, qrLogId) {
    try {
      const response = await HTTPClient.put(
        `/qrCampaign/${campaignId}/${qrLogId}`
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
