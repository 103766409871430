import {controllerAppActionTypes} from './controllerApp.actiontype';
export const actions = {
  request: () => ({ type: controllerAppActionTypes.REQUEST }),
  complete: () => ({ type: controllerAppActionTypes.COMPLETE }),

  setModuleVersions: (data) => ({ type: controllerAppActionTypes.SET_MODULE_VERSIONS, data }),
  setModules: (data) => ({ type: controllerAppActionTypes.SET_MODULES, data }),
  setVersions: (data) => ({ type: controllerAppActionTypes.SET_VERSIONS, data }),

  isExist: (data) => ({ type: controllerAppActionTypes.IS_EXIST, data }),
};