import React from "react";
import { Modal } from "react-bootstrap";
import "./style.scss";
import CloseIcon from "../assets/close.svg";
import Preview from "../preview";

const DesktopModalPreview = ({
  isPreviewModal,
  handleCloseModal,
  formData,
  isPreviewDesktop,
}) => {
  return (
    <Modal
      show={isPreviewModal}
      onHide={handleCloseModal}
      backdrop="static"
      centered
      className="desktop-modal-preview"
      backdropClassName="desktop-modal-preview-backdrop"
    >
      <Modal.Header className="border-none">
        <div className="modal-title">Preview</div>
        <img
          src={CloseIcon}
          alt="close"
          onClick={handleCloseModal}
          className="close-icon"
        />
      </Modal.Header>
      <Modal.Body className="desktop-modal-body">
        <Preview
          formData={formData}
          isPreviewDesktop={isPreviewDesktop}
          isPreviewModal={isPreviewModal}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DesktopModalPreview;
