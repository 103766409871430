import {HTTPClient} from './http.service';

export const controlCenterService = {
	async getControlCenterMalls(companyId) {
		try {
			let result = await HTTPClient.get(`/controlCenter/getMallFeatures/${companyId}`);
			return result;
		} catch (error) {
			throw error;
		}
	},
	async addMallFeatures(data) {
		try {
			let result = await HTTPClient.post('/controlCenter/addMallFeatures', data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getControlCenterChecks (companyId) {
		try {
			let result = await HTTPClient.get(`/controlCenter/${companyId}`);
			return result;
		} catch (error) {
			throw error;
		}
	},
	async getTiers (companyId, params) {
		try {
			let result = await HTTPClient.get(`/settings/tier/${companyId}`, {...params, type: 'complete'});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addTiers (mallId, data) {
		try {
			let result = await HTTPClient.post(`/settings/tier/${mallId}`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deleteTier (mallId, tierId) {
		try {
			let result = await HTTPClient.delete(`/settings/tier/${mallId}/${tierId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	async updateTiers (data,companyId, tierId) {
		try {
			let result = await HTTPClient.put(`/settings/tier/${companyId}/${tierId}`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSingleTier (companyId, tierId) {
		try {
			let result = await HTTPClient.get(`/settings/tier/${companyId}`, {tierId: tierId, type: 'complete'});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addPointsXp (mallId, data) {
		try {
			let result = await HTTPClient.post(`/settings/loyalty/addPointsXp/${mallId}`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updatePointsXp (data,id) {
		try {
			let result = await HTTPClient.put(`/controlCenter/loyalty/updatePointsXp/${id}`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getPointsXp (companyId) {
		try {
			let result = await HTTPClient.get(`/settings/loyalty/byCompany/${companyId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getCutOffXp (companyId) {
		try {
			let result = await HTTPClient.get(`/settings/tier/cutOnXp`, {instanceid: companyId});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getFeatureList (companyId) {
		try {
			let result = await HTTPClient.get(`/controlCenter/feature/byCompany/${companyId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addFeatureList (data) {
		try {
			let result = await HTTPClient.post(`/controlCenter/feature/addCompanyFeatures`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateFeatureList (data,companyId) {
		try {
			let result = await HTTPClient.put(`/controlCenter/feature/updateCompanyFeature/${companyId}`,data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getPrimaryEarnAction (companyId) {
		try {
			let result = await HTTPClient.get(`/settings/instance/primaryEarnMethod/${companyId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getPrimaryEarnActionList (companyId) {
		try {
			let result = await HTTPClient.get(`/settings/instance/primaryEarnMethodList/${companyId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updatePrimaryEarnActionList (data, companyId) {
		try {
			let result = await HTTPClient.put(`/settings/instance/primaryEarnMethod/${companyId}`, data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
}
