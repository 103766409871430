import {HTTPClient} from './http.service';
var qs = require('qs');

export const mallEnableDisable = {
    async enableMall(mallId, data) {
        try {
          let result = HTTPClient.customRequest({
            url: `/settings/instance/enableInstance/${mallId}`,
            method: 'put',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data)
          });
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    },
    async disableMall(mallId, data) {
        try {
          let result = HTTPClient.customRequest({
            url: `/settings/instance/disableInstance/${mallId}`,
            method: 'delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data)
          });
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    }
}
