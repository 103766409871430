import React from "react";

const PostLike = ({ color, width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0312 2.1875C14.2464 2.1875 16.0416 4.01042 16.0416 6.5625C16.0416 11.6667 10.5728 14.5833 8.74992 15.6771C6.927 14.5833 1.45825 11.6667 1.45825 6.5625C1.45825 4.01042 3.28117 2.1875 5.46867 2.1875C6.82492 2.1875 8.02075 2.91667 8.74992 3.64583C9.47909 2.91667 10.6749 2.1875 12.0312 2.1875Z"
        fill={color || "#1BC5BD"}
      />
    </svg>
  );
};

export default PostLike;
