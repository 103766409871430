import { HTTPClient } from "./http.service";

export const tripDetails = {
  async getDriverDetails(params) {
    try {
      const res = await HTTPClient.get(`/customers/byQuery/`, params);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getDriversDetails(params) {
    try {
      const res = await HTTPClient.get(`/earnForm/`, params);

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addDriversDetails(data) {
    try {
      const res = await HTTPClient.post(`/earnForm`, data);
      console.log("DRIVER DATA IS ", res);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
