export const mallActionTypes = {
  WATCH_MALL_INPUT_CHANGE: 'WATCH_MALL_INPUT_CHANGE',
  HANDLE_MALL_INPUT_CHANGE: 'HANDLE_MALL_INPUT_CHANGE',
  HANDLE_MALL_ERROR_CHANGE: 'HANDLE_MALL_ERROR_CHANGE',

  WATCH_ADD_MALL: 'WATCH_ADD_MALL',
  WATCH_GET_MALL_BY_ID: 'WATCH_GET_MALL_BY_ID',
  SET_BY_MALL_ID: 'SET_BY_MALL_ID',
  WATCH_EDIT_MALL: 'WATCH_EDIT_MALL',
  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',
  SUCCESS_ADD_MALL: 'SUCCESS_ADD_MALL',
  FAILED_ADD_MALL: 'FAILED_ADD_MALL',
  SUCCESS_EDIT_MALL: 'SUCCESS_EDIT_MALL',

  WATCH_GET_MALLS: 'WATCH_GET_MALLS',
  SUCCESS_FETCH_MALLS: 'SUCCESS_FETCH_MALLS',
  FAILED_FETCH_MALLS: 'FAILED_FETCH_MALLS',

  SET_MALL_LOGO: 'SET_MALL_LOGO',
  CLEAR_ADD_MALL_FORM: 'CLEAR_ADD_MALL_FORM'
};