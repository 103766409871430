import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {userActiontype} from './user.actionType';

const INIT_STATE = {
  userData: undefined,
}

const extractUserData = (userObj) => {
  const reqKeyArray = ['id', 'firstName', 'lastName', 'userType', 'isActive', 'email', 'mobile', 'roleId', 'profilePic', 'mallId'];
  return reqKeyArray.reduce((prev, key) => { 
    prev[key] = userObj[key];
    return prev
  }, {});
}

const userReducer = persistReducer(
  {storage, key: 'userDetails', whitelist:['userData']},
  (state = INIT_STATE, {type, payload}) => {
    switch(type) {
      case userActiontype.USER_PROFILE:
        return {
          ...state,
          userData: extractUserData(payload)
        }
      case userActiontype.USER_MALLSWITCH:
        return {
          ...state,
          user_MallSelected: payload
        }
      case userActiontype.USER_ONLOGOUT: 
        return INIT_STATE
      default:
        return state
    }
  }
);

export default userReducer;