import {controllerAppActionTypes} from './controllerApp.actiontype';
let initial_state = {
  versions_list: [],
  versions_list_label_value: [],
  versions_list_edit_label_value: [],
  module_versions: [],
  module_versions_value_label: [],
  modules: [],
  modules_value_label: [],
  loading: false,
  isExist: null
}

export const controllerApp  = (state = initial_state, action = {}) => {

  switch(action.type){
    case controllerAppActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case controllerAppActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case controllerAppActionTypes.SET_VERSIONS:
      return {
        ...state,
        versions_list: action.data,
        versions_list_label_value: action.data.map(v => {
          return {
            value: v.id,
            label: v.companyName,
            versionName: v.name
          }
        }),
        versions_list_edit_label_value: action.data.map(v => {
          return {
            value: v.id,
            label: v.name,
            versionType: v.versionType
          }
        }),

      }

    case controllerAppActionTypes.SET_MODULE_VERSIONS:
      return {
        ...state,
        module_versions: action.data,
        module_versions_value_label: action.data.map(m => {
          return {
            value: m.id,
            label: m.name
          }
        })
      }

    case controllerAppActionTypes.SET_MODULES:
      return {
        ...state,
        modules: action.data,
        modules_value_label: action.data.map(m => {
          return {
            value: m.id,
            label: m.moduleName
          }
        })
      }

    case controllerAppActionTypes.IS_EXIST:
      return {
        ...state,
        isExist: action.data
      }

    default:
      return state;
  }
}