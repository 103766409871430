import React from "react";

const RoundedField = ({ formData, setFormData }) => {
  return (
    <div className="round-field-ctn">
      <div className="form-subtitle-text">
        Select container and button style
      </div>
      <div className="radio-ctn">
        <div className="radio-block">
          <div className="container-shape">
            <div className="container round"></div>
          </div>
          <div className="button-shape">
            <div className="btn round"></div>
          </div>
          <div className="radio-input">
            <input
              type="radio"
              name="container-style"
              id="rounded"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, isRounded: 1 }))
              }
              // onBlur={handleInputBlur}
              value={1}
              checked={formData.isRounded === 1}
              // disabled={checkDisabled()}
            />
            <label htmlFor="rounded">Rounded</label>
          </div>
        </div>
        <div className="radio-block">
          <div className="container-shape">
            <div className="container"></div>
          </div>
          <div className="button-shape">
            <div className="btn"></div>
          </div>
          <div className="radio-input">
            <input
              type="radio"
              name="container-style"
              id="squared"
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, isRounded: 0 }))
              }
              // onBlur={handleInputBlur}
              value={0}
              checked={formData.isRounded === 0}
              // disabled={checkDisabled()}
            />
            <label htmlFor="squared">Squared</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundedField;
