import {HTTPClient} from './http.service';
import qs from 'qs';

export const refuteService = {
  async getScanList(params) {
    try {
      const {data:{data:scanList}} = await HTTPClient.get('/customerScans/', params);
      return scanList;
    }catch(e){
      return Promise.reject(e);
    }
  },
  async getScanData(id, params={}) {
    try {
      const {data:{data: scanData}} = await HTTPClient.get(`/customerScans/${id}`, params);
      return scanData;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async getAllScanData(id, params={}) {
    try {
      const {data:{data: scanData}} = await HTTPClient.get(`/customers/scans/${id}`, params);
      return scanData;
    } catch(e) {
      return Promise.reject(e);
    }
  },

  async getLastTenScans(params){
    try {
      const {data:{data: scanData}} = await HTTPClient.get(`/customerScansReport/`, params);
      return scanData;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async updateScanData(id,formData) {
    try {
      const response = await HTTPClient.customRequest({
        url: `/customerScans/${id}`,
        method: 'put',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: qs.stringify(formData)
      });
      console.log(response);
      return response;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async transferMall(mallId, customerId) {
    try {
      await HTTPClient.customRequest({
        url: `/customerScans/mall/${customerId}`,
        method: 'put',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: qs.stringify({mallId})
      });
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  }
}
