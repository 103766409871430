import React from "react";

const MobileView = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="frame">
        <path
          id="phone_iphone"
          d="M8.81814 19C8.46814 19 8.16852 18.8754 7.91927 18.6261C7.67003 18.3769 7.54541 18.0773 7.54541 17.7273V6.27273C7.54541 5.92273 7.67003 5.62311 7.91927 5.37386C8.16852 5.12462 8.46814 5 8.81814 5H15.1818C15.5318 5 15.8314 5.12462 16.0806 5.37386C16.3299 5.62311 16.4545 5.92273 16.4545 6.27273V17.7273C16.4545 18.0773 16.3299 18.3769 16.0806 18.6261C15.8314 18.8754 15.5318 19 15.1818 19H8.81814ZM8.81814 6.27273V17.7273H15.1818V6.27273H8.81814ZM12 17.4091C12.1803 17.4091 12.3314 17.3481 12.4534 17.2261C12.5753 17.1042 12.6363 16.953 12.6363 16.7727C12.6363 16.5924 12.5753 16.4413 12.4534 16.3193C12.3314 16.1973 12.1803 16.1364 12 16.1364C11.8197 16.1364 11.6685 16.1973 11.5465 16.3193C11.4246 16.4413 11.3636 16.5924 11.3636 16.7727C11.3636 16.953 11.4246 17.1042 11.5465 17.2261C11.6685 17.3481 11.8197 17.4091 12 17.4091Z"
          fill={color || "#000"}
        />
      </g>
    </svg>
  );
};

export default MobileView;
