export const controllerAppActionTypes = {
  WATCH_GET_MODULES_VERSIONS: 'WATCH_GET_MODULES_VERSIONS',
  WATCH_GET_MODULES: 'WATCH_GET_MODULES',
  WATCH_GET_VERSIONS: 'WATCH_GET_VERSIONS',
  WATCH_GET_VERSIONS_BY_COMPANY_ID: 'WATCH_GET_VERSIONS_BY_COMPANY_ID',


  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',

  SET_MODULE_VERSIONS: 'SET_MODULE_VERSIONS',
  SET_MODULES: 'SET_MODULES',
  SET_VERSIONS: 'SET_VERSIONS',
  IS_EXIST: 'IS_EXIST'
};