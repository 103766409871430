import React from "react";

const MenuHome = ({ color, className }) => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icons" clip-path="url(#clip0_2265_1121)">
        <path
          id="Vector"
          d="M5.2041 5.25141H1.99577C1.93499 5.25141 1.8767 5.22726 1.83372 5.18428C1.79075 5.14131 1.7666 5.08302 1.7666 5.02224V2.95974H1.0791L3.44571 0.808322C3.4879 0.769932 3.54289 0.748657 3.59993 0.748657C3.65698 0.748657 3.71197 0.769932 3.75416 0.808322L6.12077 2.95974H5.43327V5.02224C5.43327 5.08302 5.40912 5.14131 5.36615 5.18428C5.32317 5.22726 5.26488 5.25141 5.2041 5.25141ZM2.22493 4.79307H4.97493V2.53738L3.59993 1.28751L2.22493 2.53738V4.79307ZM2.68327 3.87641H4.5166V4.33474H2.68327V3.87641Z"
          fill={color || "#1BC5BD"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_1121">
          <rect
            width="5.5"
            height="5.5"
            fill="white"
            transform="translate(0.850098 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuHome;
