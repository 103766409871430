import { HTTPClient } from "./http.service";

export const appService = {
  async getAppConstant() {
    const mallId = HTTPClient.getMallId();
    let client = null;
    if (!mallId) {
      client = Object.assign({}, HTTPClient);
      // client.saveHeader({ key: "instanceId", value: 0 });
      client.deleteHeader("instanceId");
    } else {
      client = HTTPClient;
    }
    // if (mallId) {
    try {
      const {
        data: { data: appConstant },
      } = await client.get(
        `${process.env.REACT_APP_CONST_URL}/constants/getConstants`
      );
      return appConstant;
    } catch (e) {
      console.log(e);
    }
    // } else return {};
  },
  async getMenuObj() {
    const mallId = HTTPClient.getMallId();

    // if (mallId) {
    try {
      const {
        data: { data: menuObj },
      } = await HTTPClient.get("/modules/getMenuObject");
      return menuObj;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
    // }
    // return {};
  },
  async getModulesList() {
    const mallId = HTTPClient.getMallId();
    let client = null;
    if (!mallId) {
      client = Object.assign({}, HTTPClient);
      client.saveHeader({ key: "instanceId", value: 0 });
    } else {
      client = HTTPClient;
    }
    try {
      const {
        data: { data: allModules },
      } = await client.get("/modules/getModules");
      return allModules;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
    // } else return {};
  },
  async getMallsList() {
    const mallId = HTTPClient.getMallId();
    if (mallId) {
      try {
        const {
          data: { data: docs },
        } = await HTTPClient.get("/mall/");
        return docs;
      } catch (e) {
        console.log(e.data.status);
        return Promise.reject(e.data.status);
      }
    } else return {};
  },
};
