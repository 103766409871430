import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
// import Overall from "./DashboardNew/Overall";
// import Earn from "./DashboardNew/Earn";
// import Loyalty from "./DashboardNew/Loyalty";
// import Tier from "./DashboardNew/Tier";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";
import NewDashboardDemo from "./NewDashboardDemo";
import Branding from "./Branding";
// import MallSwitchModal from '../component/mallSwitchModal';

// import '../style/customs.scss';
// import UserManagement from './UserManagement';

const AccessDenied = lazy(() => import("./Access/accessDenied"));

const Notification = lazy(() => import("./notification/index"));

const Retailer = lazy(() => import("./retailer/index"));

const UserManagement = lazy(() => import("./UserManagement"));

const CustomerProfile = lazy(() => import("./CustomerManagement"));

// const UserManagement = lazy(() => import('./UserManagement'));
const CouponManagementLite = lazy(() => import("./couponManagement"));
const CouponManagementFull = lazy(() => import("./couponManagementFull"));
const QRCodeGeneration = lazy(() => import("./qrCodeGeneration"));
const UPICashback = lazy(() => import("./UPICashback"));
const EarnForm = lazy(() => import("./earnForm"));
const RefuteManagement = lazy(() => import("./refuteManagement"));
const ScanAndEarnManagement = lazy(() =>
  import("./scanAndEarnManagementAriston")
);

const BookingManagement = lazy(() => import("./BookingManagement/index"));

const RuleEngine = lazy(() => import("./RuleEngine"));

const RewardManagement = lazy(() => import("./rewardManagement/index"));

const Banners = lazy(() => import("./ContentManagement/Banners/index"));

const OnboardingScreens = lazy(() =>
  import("./ContentManagement/OnboardingScreens/index")
);

const ControllersApp = lazy(() => import("./controllersApp"));
const Companies = lazy(() => import("./Companies"));

const MallFeedManager = lazy(() =>
  import("./ContentManagement/MallFeedManager/index")
);

const ProfilePage = lazy(() => import("./profile"));

const Parking = lazy(() => import("./Parking"));

const BaggageService = lazy(() => import("./BaggageService/index"));

const Dineout = lazy(() => import("./Dineout/index"));

const HandlerApp = lazy(() => import("./HandlerApp/index"));

const Referral = lazy(() => import("./ReferralManagement/index"));
const ReferralNew = lazy(() => import("./ReferralManagementNew/index"));
const Quiz = lazy(() => import("./Quiz/index"));

const CustomerDelight = lazy(() => import("./CustomerDelight/index"));
const Survey = lazy(() => import("./Survey"));
const ScanReports = lazy(() => import("./Reports/ScanReport"));

const CouponRedemptionReport = lazy(() =>
  import("./Reports/CouponRedemptionReportFolder/index")
);

// const CouponRedemptionReportView = lazy(() =>
//   import('./Reports/CouponRedemptionReportView')
// );

// const CouponRedemptionReportViewUsers = lazy(() =>
//   import('./Reports/CouponRedemptionReportViewUsers')
// );

// const CustomerWiseCouponReport = lazy(() =>
//   import('./Reports/CustomerWiseCouponReport')
// );

const RewardRedemptionReport = lazy(() =>
  import("./Reports/RewardRedemptionReportFolder/index")
);

const ContactlessPaymentLogReport = lazy(() =>
  import("./Reports/ContactLessPaymentLogReport")
);

const ReportList = lazy(() => import("./Reports/ReportList"));

const FavStoreList = lazy(() => import("./Reports/FavStoreReport"));

const CustomersByDOB = lazy(() => import("./Reports/CustomersByDob"));

const CustomersByAnniversary = lazy(() =>
  import("./Reports/CustomersByAnniversary")
);

const ShopAndWin = lazy(() => import("./ShopAndWin/index"));

const ScratchCardManagement = lazy(() =>
  import("./ScratchCardManagement/index")
);

const IssueManagement = lazy(() => import("./IssueManagement/index"));

const SuperUser = lazy(() => import("./SuperUser/index"));

const DeepLink = lazy(() => import("./DeepLink/index"));

const FootfallReport = lazy(() => import("./FootfallReport/index"));

const ConciergePhysicalDistVoucher = lazy(() =>
  import("./Concierge/PhysicalDistVoucher/index")
);

const ConciergePhysicalRewardDistVoucher = lazy(() =>
  import("./Concierge/PhysicalDistVoucher/index1")
);

const PersonalisedMallVisit = lazy(() =>
  import("./PersonalisedMallVisit/index")
);

const ScanService = lazy(() => import("./ScanService/index"));

const SpinTheWheel = lazy(() => import("./SpinTheWheel"));

const OtherIssues = lazy(() => import("./OtherIssues"));

const VoucherManagement = lazy(() => import("./VoucherManagement/index"));

const OldDashboard = lazy(() => import("./DashboardPage"));

const NewDashboard = lazy(() => import("./DashboardNew/"));

const StaticPages = lazy(() => import("./StaticPages"));

// const TripDetails = lazy(() => import("./TripDetails"));
// const DriverDetails = lazy(() => import("./TripDetails/DriverDetails"));

const DefaultPage = ({ mallModalOpen, changePageHeading }) => {
  const appConstant = useSelector(
    ({ appData: { globalConstants } }) => globalConstants
  );
  const modulesList = useSelector(
    ({ modulesAndMalls: { modulesList } }) => modulesList
  );
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      changePageHeading(pageTitle.title);
      checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  const userType = useSelector(
    ({
      user: {
        userData: { userType },
      },
    }) => userType
  );
  const selectedMall = useSelector(
    ({ modulesAndMalls }) => modulesAndMalls.selectedMall
  );

  if (
    !(
      (userType === 2 && selectedMall) ||
      userType === 3 ||
      userType === 4 ||
      userType === 5
    )
  ) {
    return (
      <Suspense>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/new/overview" />
          }
          <Route path="/profile" component={ProfilePage} />
          <Route
            path="/new/overview"
            render={(props) => (
              <NewDashboard
                {...props}
                renderComponent={(title, Component, childProps) =>
                  renderComponentFP(Component, {
                    title,
                    moduleKey: "",
                  })(childProps)
                }
              />
            )}
          />
          <Route
            path="/companies"
            render={(props) =>
              // renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
              renderComponentFP(Companies, PageName.COMPANIES)(props)
            }
          />
          <Route
            path="/super-user"
            render={(props) =>
              // renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
              renderComponentFP(SuperUser, PageName.SUPERUSER)(props)
            }
          />
          <Redirect exact from="*" to="/" />
        </Switch>
      </Suspense>
    );
  }

  return appConstant && modulesList ? (
    <Suspense>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/new/overview" />
        }

        <Route
          path="/companies"
          render={(props) =>
            // renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
            renderComponentFP(Companies, PageName.COMPANIES)(props)
          }
        />
        <Route
          path="/super-user"
          render={(props) =>
            // renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
            renderComponentFP(SuperUser, PageName.SUPERUSER)(props)
          }
        />

        <Route
          path="/old-dashboard"
          render={(props) =>
            renderComponentFP(OldDashboard, {
              title: "old dashboard",
              moduleKey: "",
            })(props)
          }
        />
        <Route
          path="/dashboard"
          render={(props) =>
            renderComponentFP(OldDashboard, {
              title: "new dashboard",
              moduleKey: "",
            })(props)
          }
        />

        <Route
          path="/branding"
          render={(props) =>
            renderComponentFP(withAllowed(Branding), PageName.BRANDING)(props)
          }
        />

        <Route
          path="/new/overview"
          render={(props) => (
            <NewDashboard
              {...props}
              renderComponent={(title, Component, childProps) =>
                renderComponentFP(Component, {
                  title,
                  moduleKey: "",
                })(childProps)
              }
            />
          )}
        />

        {/* <Route
          path="/notifications"
          render={(props) =>
            renderComponentFP(
              withAllowed(Notification),
              PageName.NOTIFICATIONCENTER
            )(props)
          }
        /> */}
        <Route
          path="/retailers"
          render={(props) =>
            renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
          }
        />
        <Route
          path="/controllers"
          render={(props) =>
            // renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
            renderComponentFP(ControllersApp, PageName.CONTROLLERS_APP)(props)
          }
        />
        <Route
          path="/user-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(UserManagement),
              PageName.USERMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/refute-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(RefuteManagement),
              PageName.REFUTEMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/scan-and-earn-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScanAndEarnManagement),
              PageName.SCANANDEARNMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/coupon-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponManagementLite),
              PageName.COUPONLITE
            )(props)
          }
        />
        <Route
          path="/coupon-management-full"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponManagementFull),
              PageName.COUPONPRO
            )(props)
          }
        />
        <Route
          path="/qr-code-generation"
          render={(props) =>
            renderComponentFP(
              withAllowed(QRCodeGeneration),
              PageName.QRCODEGENERATION
            )(props)
          }
        />
        <Route
          path="/upi-cashback"
          render={(props) =>
            renderComponentFP(
              withAllowed(UPICashback),
              PageName.UPICASHBACK
            )(props)
          }
        />
        <Route
          path="/driver-details"
          render={(props) =>
            renderComponentFP(EarnForm, PageName.COUPONPRO)(props)
          }
        />
        <Route
          path="/scratch-card-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScratchCardManagement),
              PageName.SCRATCHCARDMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/customer-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomerProfile),
              PageName.CUSTOMERS
            )(props)
          }
        />
        <Route
          path="/booking-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(BookingManagement),
              PageName.BOOKINGMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/banner"
          render={(props) =>
            renderComponentFP(withAllowed(Banners), PageName.BANNERS)(props)
          }
        />
        <Route
          path="/onboarding-screens"
          render={(props) =>
            renderComponentFP(
              withAllowed(OnboardingScreens),
              PageName.ONBORADINGSCREEN
            )(props)
          }
        />
        <Route
          path="/feed-managers"
          render={(props) =>
            renderComponentFP(
              withAllowed(MallFeedManager),
              PageName.MALLFEEDMANAGER
            )(props)
          }
        />
        <Route
          path="/rule-engine"
          render={(props) =>
            renderComponentFP(
              withAllowed(RuleEngine),
              PageName.RULEENGINER
            )(props)
          }
        />
        <Route
          path="/reward-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(RewardManagement),
              PageName.REWARDMANAGEMENT
            )(props)
          }
        />
        <Route path="/profile" component={ProfilePage} />
        <Route
          path="/parking"
          render={(props) =>
            renderComponentFP(withAllowed(Parking), PageName.PARKING)(props)
          }
        />
        <Route
          path="/baggage-services"
          render={(props) =>
            renderComponentFP(
              withAllowed(BaggageService),
              PageName.BAGGAGESERVICES
            )(props)
          }
        />
        <Route
          path="/dineout"
          render={(props) =>
            renderComponentFP(withAllowed(Dineout), PageName.DINEOUT)(props)
          }
        />
        <Route
          path="/deep-link-management"
          render={(props) =>
            renderComponentFP(withAllowed(DeepLink), PageName.DEEPLINK)(props)
          }
        />
        <Route
          path="/footfall-report"
          render={(props) =>
            renderComponentFP(FootfallReport, PageName.FOOTFALLREPORT)(props)
          }
        />
        {/* Report Starts */}
        <Route
          path="/scan-reports"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScanReports),
              PageName.SCAN_REPORT
            )(props)
          }
        />
        <Route
          path="/coupon-redemption"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponRedemptionReport),
              PageName.COUPON_REDEMPTION_REPORT
            )(props)
          }
        />
        {/* <Route path='/coupon-redemption/view/:id/details' render={props => renderComponentFP(CouponRedemptionReportView,PageName.COUPON_REDEMPTION_REPORT)(props)} />
        <Route path='/coupon-redemption/view/:id/users' render={props => renderComponentFP(CouponRedemptionReportViewUsers,PageName.COUPON_REDEMPTION_REPORT)(props)} />
        <Route path='/customer-wise-coupon-report' render={props => renderComponentFP(CustomerWiseCouponReport,PageName.COUPON_REDEMPTION_REPORT)(props)} /> */}
        <Route
          path="/reward-redemption"
          render={(props) =>
            renderComponentFP(
              withAllowed(RewardRedemptionReport),
              PageName.REWARD_REDEMPTION_REPORT
            )(props)
          }
        />
        {/* <Route path='/reward-redemption/view/:id/details' render={props => renderComponentFP(RewardRedemptionReportView,PageName.REWARD_REDEMPTION_REPORT)(props)} />
        <Route path='/reward-redemption/view/:id/users' render={props => renderComponentFP(RewardRedemptionReportViewUsers,PageName.REWARD_REDEMPTION_REPORT)(props)} />
        <Route path='/customer-wise-reward-report' render={props => renderComponentFP(CustomerWiseRewardReport,PageName.REWARD_REDEMPTION_REPORT)(props)} /> */}
        <Route
          path="/contactless-payment-log-report"
          render={(props) =>
            renderComponentFP(
              withAllowed(ContactlessPaymentLogReport),
              PageName.CONTACTLESS_PAYMENT_REPORT
            )(props)
          }
        />
        <Route
          path="/report-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(ReportList),
              PageName.REPORTSMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/fav-store-report-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(FavStoreList),
              PageName.FAVORITESTOREREPORT
            )(props)
          }
        />

        <Route
          path="/customers-by-dob-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomersByDOB),
              PageName.CUSTOMERBYDOB
            )(props)
          }
        />

        <Route
          path="/customers-by-anniversary-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomersByAnniversary),
              PageName.CUSTOMERBYANIVVERSARY
            )(props)
          }
        />
        {/* Report Ends */}
        <Route
          path="/voucher"
          render={(props) =>
            renderComponentFP(
              VoucherManagement,
              PageName.VOUCHERMANAGEMENTBACKEND
            )(props)
          }
        />
        <Route
          path="/survey"
          render={(props) => renderComponentFP(Survey, PageName.SURVEY)(props)}
        />
        <Route
          path="/shop-and-win"
          render={(props) =>
            renderComponentFP(
              withAllowed(ShopAndWin),
              PageName.SHOPANDWIN
            )(props)
          }
        />
        <Route
          path="/spin-the-wheel"
          render={(props) =>
            renderComponentFP(
              withAllowed(SpinTheWheel),
              PageName.SPINWHEEL
            )(props)
          }
        />
        <Route
          path="/other-issues"
          render={(props) =>
            renderComponentFP(
              withAllowed(OtherIssues),
              PageName.OTHERISSUES
            )(props)
          }
        />
        <Route
          path="/referral"
          render={(props) =>
            renderComponentFP(withAllowed(Referral), PageName.REFFERAL)(props)
          }
        />
        <Route
          path="/revamp"
          render={(props) =>
            renderComponentFP(
              withAllowed(ReferralNew),
              PageName.REFFERAL_NEW
            )(props)
          }
        />
        <Route
          path="/quiz"
          render={(props) =>
            renderComponentFP(withAllowed(Quiz), PageName.QUIZ)(props)
          }
        />
        <Route
          path="/customer-delight"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomerDelight),
              PageName.CUSTOMERDELIGHT
            )(props)
          }
        />
        <Route
          path="/issue-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(IssueManagement),
              PageName.ISSUEMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/concierge-physical-distribution-coupon-vouchers"
          render={(props) =>
            renderComponentFP(
              withAllowed(ConciergePhysicalDistVoucher),
              PageName.PHYSICALVOUCHERDISTRIBUTION
            )(props)
          }
        />
        <Route
          path="/concierge-physical-distribution-reward-vouchers"
          render={(props) =>
            renderComponentFP(
              withAllowed(ConciergePhysicalRewardDistVoucher),
              PageName.PHYSICALVOUCHERDISTRIBUTION
            )(props)
          }
        />
        <Route
          path="/personalised-plan-visit"
          render={(props) =>
            renderComponentFP(
              withAllowed(PersonalisedMallVisit),
              PageName.PERSONALISEDMALLVISIT
            )(props)
          }
        />
        <Route
          path="/handler"
          render={(props) =>
            renderComponentFP(
              withAllowed(HandlerApp),
              PageName.HANDERLARAPP
            )(props)
          }
        />
        <Route
          path="/scan-service"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScanService),
              PageName.SCANSERVICE
            )(props)
          }
        />
        <Route
          path="/voucher"
          render={(props) =>
            renderComponentFP(
              withAllowed(VoucherManagement),
              PageName.NOTIFICATIONCENTER
            )(props)
          }
        />

        <Route
          path="/static-pages"
          render={(props) =>
            renderComponentFP(StaticPages, PageName.STATIC_PAGES)(props)
          }
        />

        <Route
          path="/new-dashboard-demo"
          render={(props) =>
            renderComponentFP(
              NewDashboardDemo,
              PageName.NEW_DASHBOARD_DEMO
            )(props)
          }
        />

        {/* <Route
          path="/trip-details"
          render={(props) =>
            renderComponentFP(TripDetails, PageName.STATIC_PAGES)(props)
          }
        /> */}
        {/* <Route
          path="/driver-details"
          render={(props) =>
            renderComponentFP(DriverDetails, PageName.STATIC_PAGES)(props)
          }
        /> */}

        <Route path="/access-denied" component={AccessDenied} />
        <Redirect to="/error/error-v1" />
        {/* <Route path='/customer-management' component={CustomerProfile} />
        <Route path='/booking-management' component={BookingManagement} /> */}
      </Switch>
      {/* <MallSwitchModal isOpen={mallModalOpen} toggle={toogleMallModal}/> */}
    </Suspense>
  ) : (
    <LayoutSplashScreen visible={true} />
  );
};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(DefaultPage);
