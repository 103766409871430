export const companyActionTypes = {
  WATCH_INPUT_CHANGE: "WATCH_INPUT_CHANGE",
  HANDLE_INPUT_CHANGE: "HANDLE_INPUT_CHANGE",
  HANDLE_ERROR_CHANGE: "HANDLE_ERROR_CHANGE",

  WATCH_ADD_COMPANY: "WATCH_ADD_COMPANY",
  WATCH_GET_COMPANY_BY_ID: "WATCH_GET_COMPANY_BY_ID",
  SET_BY_COMAPNY_ID: "SET_BY_COMAPNY_ID",
  WATCH_EDIT_COMPANY: "WATCH_EDIT_COMPANY",
  REQUEST: "REQUEST",
  COMPLETE: "COMPLETE",
  SUCCESS_ADD_COMPANIES: "SUCCESS_ADD_COMPANIES",
  FAILED_ADD_COMPANY: "FAILED_ADD_COMPANY",
  SUCCESS_EDIT_COMPANY: "SUCCESS_EDIT_COMPANY",

  WATCH_GET_COMPANIES: "WATCH_GET_COMPANIES",
  SUCCESS_FETCH_COMPANIES: "SUCCESS_FETCH_COMPANIES",
  FAILED_FETCH_COMPANIES: "FAILED_FETCH_COMPANIES",

  SET_COMPANY_LOGO: "SET_COMPANY_LOGO",
  SET_FAVICON: "SET_FAVICON",
  SET_SIGNUP_IMAGE: "SET_SIGNUP_IMAGE",
  CLEAR_ADD_COMPANY_FORM: "CLEAR_ADD_COMPANY_FORM",
  RESET_COMPANY_ACCOUNT_DETAILS: "RESET_COMPANY_ACCOUNT_DETAILS",
};
