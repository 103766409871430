import React from "react";

const MenuSearch = ({ color, width, height, className }) => {
  return (
    <svg
      width={width || "8"}
      height={height || "7"}
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icons/search">
        <path
          id="Vector"
          d="M5.38227 4.75799L6.36379 5.73929L6.03952 6.06356L5.05823 5.08204C4.6931 5.37473 4.23895 5.53393 3.771 5.53326C2.6325 5.53326 1.7085 4.60926 1.7085 3.47076C1.7085 2.33226 2.6325 1.40826 3.771 1.40826C4.9095 1.40826 5.8335 2.33226 5.8335 3.47076C5.83416 3.93872 5.67496 4.39287 5.38227 4.75799ZM4.92256 4.58795C5.2134 4.28886 5.37582 3.88795 5.37516 3.47076C5.37516 2.58435 4.65718 1.8666 3.771 1.8666C2.88458 1.8666 2.16683 2.58435 2.16683 3.47076C2.16683 4.35695 2.88458 5.07493 3.771 5.07493C4.18818 5.07559 4.58909 4.91317 4.88818 4.62233L4.92256 4.58795V4.58795Z"
          fill={color || "#1C1C1C"}
        />
      </g>
    </svg>
  );
};

export default MenuSearch;
