import React from "react";

const MenuProfile = ({ color, className }) => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="default-profile">
        <g clip-path="url(#clip0_2265_1158)">
          <rect
            x="0.800293"
            y="0.199951"
            width="8.8"
            height="8.8"
            rx="4.4"
            fill="white"
          />
          <g id="Ellipse 422">
            <circle cx="8.6377" cy="8.86249" r="4.8125" fill="white" />
            <circle
              cx="8.6377"
              cy="8.86249"
              r="4.8125"
              fill="#3A66D9"
              fill-opacity="0.1"
            />
          </g>
          <circle
            id="Ellipse 6"
            cx="4.2376"
            cy="3.08745"
            r="1.5125"
            fill={color || "#1BC5BD"}
          />
        </g>
        <rect
          x="0.869043"
          y="0.268701"
          width="8.6625"
          height="8.6625"
          rx="4.33125"
          stroke="#D9D9D9"
          stroke-width="0.1375"
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_1158">
          <rect
            x="0.800293"
            y="0.199951"
            width="8.8"
            height="8.8"
            rx="4.4"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuProfile;
