import {HTTPClient} from './http.service';
var qs = require('qs');

export const reportsService = {
  async getScanReport(params) {
    try {
      const {data: {data}} = await HTTPClient.get('/customerScansReport/', params);
      return data;
    }catch(e){
      return Promise.reject(e);
    }
  },
  async getCouponRedemptionReports (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/couponRedemptionReports/', params);
      return data;
    }catch(e){
      return Promise.reject(e);
    }
  },
  async getCouponRedemtionInfo (id, from, to) {
    try {
      const {data: {data}} = await HTTPClient.get(`/couponRedemptionReports/graphData/?couponId=${id}&fromDate=${from}&toDate=${to}`);
      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async getCouponWiseUserData (params, id) {
    try {
      const {data: {data}} = await HTTPClient.get(`/couponRedemptionReports/userData/?couponId=${id}`, params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
  async getRewardRedemptionReports (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/rewardRedemptionReports/', params);
      return data;
    }catch(e){
      return Promise.reject(e);
    }
  },
  async getRewardWiseUserData (params, id) {
    try {
      const {data: {data}} = await HTTPClient.get(`/rewardRedemptionReports/userData/?rewardId=${id}`, params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
  async getRewardRedemtionInfo (id, from, to) {
    try {
      const {data: {data}} = await HTTPClient.get(`/rewardRedemptionReports/graphData/?rewardId=${id}&fromDate=${from}&toDate=${to}`);
      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async getContactlessPaymentLogReport (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/paymentReports/', params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
  async getReportList (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/reportsManagement/', params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
  async getCustomerWiseCouponReport (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/couponRedemptionReports/couponReportsList', params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
  async getCustomerWiseRewardReport (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/rewardRedemptionReports/rewardReportsList', params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
	async downloadReports(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/reportsManagement/createReport`,
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
  async getFavStoreReport (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/favouriteStoreReport/', params);
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },

  async getCustomersByAnniversary (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/customerByAnniversary/', {...params, status:2});
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },

  async getCustomersByDob (params) {
    try {
      const {data: {data}} = await HTTPClient.get('/customerByDob/', {...params, status:2});
      return data;
    } catch(e){
      return Promise.reject(e);
    }
  },
}
