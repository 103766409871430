import { takeLatest, put, all, call } from 'redux-saga/effects';
import { actions } from './controllerApp.action';
import { controllerAppActionTypes } from './controllerApp.actiontype';
import { controllerAppService } from '../../service';

function* handlegetModuleVersions () {
  try {
    yield put(actions.request())
    const {data: {data}} = yield controllerAppService.getModuleVersions();
    yield put(actions.setModuleVersions(data));
  } catch (error) {
    console.log('ERROR WHILE FETCHING MODULES VERSIONS', error);
  } finally {
    yield put(actions.complete())
  }
}

function* handlegetModules () {
  try {
    yield put(actions.request())
    const {data: {data}} = yield controllerAppService.getModules();
    yield put(actions.setModules(data));
  } catch (error) {
    console.log('ERROR WHILE FETCHING MODULES', error);
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetVersions () {
  try {
    yield put(actions.request())
    const {data: {data}} = yield controllerAppService.getVersions();
    yield put(actions.setVersions(data));
  } catch (error) {
    console.log('ERROR WHILE FETCHING MODULES', error);
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetVersionsByCompanyId ({id}) {
  try {
    yield put(actions.request())
    const {data: {data: {data, isExist}}} = yield controllerAppService.getVersionsByCompanyId(id);
    yield put(actions.setVersions(data));
    yield put(actions.isExist(isExist))
  } catch (error) {
    console.log('ERROR WHILE FETCHING MODULES', error);
  } finally {
    yield put(actions.complete())
  }
}

export function* getModuleVersions() {
  yield takeLatest(controllerAppActionTypes.WATCH_GET_MODULES_VERSIONS, handlegetModuleVersions)
}

export function* getModules() {
  yield takeLatest(controllerAppActionTypes.WATCH_GET_MODULES, handlegetModules)
}

export function* getVersions() {
  yield takeLatest(controllerAppActionTypes.WATCH_GET_VERSIONS, handleGetVersions)
}

export function* getVersionsByCompanyId() {
  yield takeLatest(controllerAppActionTypes.WATCH_GET_VERSIONS_BY_COMPANY_ID, handleGetVersionsByCompanyId)
}

export function* controllerAppSaga() {
  yield all([
    call(getModuleVersions),
    call(getModules),
    call(getVersions),
    call(getVersionsByCompanyId)
  ]);
}
