import React from "react";

const MenuNotification = ({ color, width, height, className }) => {
  return (
    <svg
      width={width || "8"}
      height={height || "7"}
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="frame" clip-path="url(#clip0_2265_2329)">
        <g id="Group 11572">
          <path
            id="Subtract"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.8501 4.93744H6.3001V3.01244C6.3001 2.96412 6.29851 2.91596 6.29536 2.86804C6.13464 2.95994 5.94852 3.01246 5.75012 3.01246C5.75011 3.01246 5.75011 3.01246 5.7501 3.01246V4.93744H2.4501V3.01244C2.4501 2.57483 2.62394 2.15515 2.93337 1.84571C3.24281 1.53628 3.66249 1.36244 4.1001 1.36244C4.31999 1.36244 4.53535 1.40633 4.73448 1.48926C4.80854 1.31171 4.92782 1.15772 5.07791 1.04168C4.7768 0.89229 4.44257 0.812439 4.1001 0.812439C3.51662 0.812439 2.95704 1.04422 2.54446 1.4568C2.13188 1.86938 1.9001 2.42896 1.9001 3.01244V4.93744H1.3501V5.48744H6.8501V4.93744ZM4.9251 6.58744V6.03744H3.2751V6.58744H4.9251Z"
            fill={color}
          />
          <circle
            id="Ellipse 490"
            cx="5.76103"
            cy="1.91777"
            r="0.825"
            fill="#1BC5BD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2265_2329">
          <rect
            width="6.6"
            height="6.6"
            fill="white"
            transform="translate(0.800293 0.399963)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuNotification;
