import { takeLatest, put, all, call } from 'redux-saga/effects';
import { notificationActionTypes } from './notification.actiontype';
import { notificationService } from '../../service';
import { actions } from './notification.action';
import {showToastMessage} from './../appStore/appStore.action'

function* handleGetCohort () {
  try {
    let result  = yield notificationService.cohortList();
    yield put(actions.getCohorts(result.data.data.docs.data))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleGetTiers () {
  try {
    let result  = yield notificationService.tierList();
    yield put(actions.getTiers(result.data.data.docs))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleAddNotification ({data, history}) {
  try {
    yield put(actions.request())
    let result = yield notificationService.addNotification(data)
    yield put(showToastMessage({type: 'success', message: 'Notification has been created successfully!'}))
    history.push('/notifications/all')
    return result;
  } catch(error) {
    yield put({type: notificationActionTypes.FAILED_ADD_NOTIFICATION, data: error})
  } finally {
    yield put(actions.complete())
  }
}

function* handlegetNotifications ({data}) {
  try {
    yield put(actions.request())
    let result = yield notificationService.getNotifications(data)
    yield put(actions.getNotifications({notifications: result.data.data.docs.data, total: result.data.data.totalEntries}))
  } catch(error) {
    console.log(error)
  } finally {
    yield put(actions.complete())
  }
}

function* handleDeleteNotification ({data}) {
  try {
    yield put(actions.request())
    yield notificationService.deleteNotification(data)
    yield put(actions.deleteNotification(data))
  } catch(error) {
    console.log(error)
  } finally {
    yield put(actions.complete())
  }
}

function* handlegetNotificationById ({data}) {
  try {
    let result = yield notificationService.getNotificationById(data)
    return result;
  } catch(error) {
    console.log(error)
  }
}

function* handleEditNotification ({data, id, history}) {
  try {
    yield put(actions.request())
    let result = yield notificationService.editNotification(data, id)
    yield put(actions.successEditNotificaiton({data: result.data.status.message}))
    yield put(showToastMessage({type: 'success', message: 'Notification has been updated successfully!'}))
    history.push('/notifications/all')
    return result;
  } catch(error) {
    yield put({type: notificationActionTypes.FAILED_ADD_NOTIFICATION, data: error})
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetRedirectionList () {
  try {
    let {data: {data}} = yield notificationService.getNotificationRedirectionList()
    yield put(actions.getRedirectionTypeList(data))
  } catch(error) {
    console.log(error)
  } finally {
  }
}

export function* getCohortList() {
  yield takeLatest(notificationActionTypes.WATCH_GET_COHORT, handleGetCohort)
}

export function* getTierList() {
  yield takeLatest(notificationActionTypes.WATCH_GET_TIERS, handleGetTiers)
}

export function* watchAddNotification () {
  yield takeLatest(notificationActionTypes.WATCH_ADD_NOTIFICATION, handleAddNotification)
}

export function* watchGetNotifications () {
  yield takeLatest(notificationActionTypes.WATCH_GET_NOTIFICATIONS, handlegetNotifications)
}

export function* watchDeleteNotification () {
  yield takeLatest(notificationActionTypes.WATCH_DELETE_NOTIFICATIONS, handleDeleteNotification)
}

export function* watchGetNotificationById () {
  yield takeLatest(notificationActionTypes.WATCH_GET_NOTIFICATION_BY_ID, handlegetNotificationById)
}

export function* watchEditNotification () {
  yield takeLatest(notificationActionTypes.WATCH_EDIT_NOTIFICATION, handleEditNotification)
}

export function* watchGetRedirectionList () {
  yield takeLatest(notificationActionTypes.WATCH_REDIRECTION_TYPE_LIST, handleGetRedirectionList)
}

export function* notificationSaga() {
  yield all([
    call(getCohortList),
    call(getTierList),
    call(watchAddNotification),
    call(watchGetNotifications),
    call(watchDeleteNotification),
    call(watchGetNotificationById),
    call(watchEditNotification),
    call(watchGetRedirectionList)
  ]);
}