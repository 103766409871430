import { companyActionTypes } from "./company.actiontype";
import {
  initialState,
  serverToClientKeyValueMapping,
} from "./company.initial_state";

export const Company = (state = initialState, action = {}) => {
  switch (action.type) {
    // Add company
    case companyActionTypes.HANDLE_INPUT_CHANGE:
      const { key, target } = action.data;
      const { name, value } = target;

      return {
        ...state,
        form: {
          ...state.form,
          [key]: {
            ...state.form[key],
            [name]: {
              ...state.form[key][name],
              input_val: value,
            },
          },
        },
      };

    // form errors
    case companyActionTypes.HANDLE_ERROR_CHANGE:
      if (!action.data.error) {
        return {
          ...state,
          form: {
            ...state.form,
            client_errors: state.form.client_errors.filter(
              (error) => error.key !== action.data.name
            ),
          },
        };
      } else {
        let indexIs = state.form.client_errors.findIndex((error) => {
          return error.key === action.data.name;
        });
        if (indexIs > -1) {
          return {
            ...state,
            form: {
              ...state.form,
              client_errors: [
                ...state.form.client_errors.filter(
                  (error) => error.key !== action.data.name
                ),
                action.data.error,
              ],
            },
          };
        } else {
          return {
            ...state,
            form: {
              ...state.form,
              client_errors: [...state.form.client_errors, action.data.error],
            },
          };
        }
      }

    case companyActionTypes.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case companyActionTypes.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    case companyActionTypes.SUCCESS_ADD_COMPANIES:
      return {
        ...state,
        form: initialState.form,
      };

    case companyActionTypes.FAILED_ADD_COMPANY:
      if (action.data.error_type === "CLIENT") {
        return {
          ...state,
          form: {
            ...state.form,
            client_errors: action.data.errors,
          },
        };
      } else {
        return {
          ...state,
          form: {
            ...state.form,
            server_errors: action.data.errors,
          },
        };
      }

    case companyActionTypes.SUCCESS_FETCH_COMPANIES:
      return {
        ...state,
        companies: action.data.companies,
        total_companies: action.data.total_companies,
        loading: false,
      };

    case companyActionTypes.FAILED_FETCH_COMPANIES:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case companyActionTypes.SET_BY_COMAPNY_ID:
      // let temp_obj = initialState.form;
      // for (const [key] of Object.entries(state.form)) {
      //   if (key === 'client_errors' || key === 'server_errors') break;
      //   for (const [key1, value1] of Object.entries(action.data)) {
      //     if (serverToClientKeyValueMapping[key][key1] && key !== 'bank_details') {
      //       temp_obj[key][serverToClientKeyValueMapping[key][key1]]['input_val'] = value1
      //     }
      //   }
      // }

      // for (const [key] of Object.entries(state.form)) {
      //   if (key === 'client_errors' || key === 'server_errors') break;
      //   for (const [key1, value1] of Object.entries(action.data.bankDetails)) {
      //     if (serverToClientKeyValueMapping[key][key1] && key === 'bank_details') {
      //       temp_obj[key][serverToClientKeyValueMapping[key][key1]]['input_val'] = value1
      //     }
      //   }
      // }

      // return {
      //   ...state,
      //   company_id: action.data.id,
      //   form: temp_obj
      // }
      return {
        ...state,
        company_id: action.data.id,
        form: {
          ...state.form,
          company_information: {
            ...state.form.company_information,
            company_name: {
              ...state.form.company_information.company_name,
              input_val: action.data.instanceName,
            },
            company_logo: {
              ...state.form.company_information.company_logo,
              logoUrl: action.data.instanceLogo,
            },
            favicon: {
              ...state.form.company_information.favicon,
              faviconUrl: action.data.faviconUrl,
            },
            signup_image: {
              ...state.form.company_information.signup_image,
              signupUrl: action.data.signUpImage,
            },
            access_key: {
              ...state.form.company_information.access_key,
              input_val: action.data.accessKey,
            },
            // company_GST: {
            //   ...state.form.company_information.company_GST,
            //   input_val: action.data.companyGST,
            // },
          },
          contact_information: {
            ...state.form.contact_information,
            email: {
              ...state.form.contact_information.email,
              input_val: action.data.instanceEmail,
            },
            country_code_primary: {
              ...state.form.contact_information.country_code_primary,
              input_val: action.data.countryCodePrimary,
            },
            contact_number: {
              ...state.form.contact_information.contact_number,
              input_val: action.data.instancePrimaryContact,
            },
            country_code_secondary: {
              ...state.form.contact_information.country_code_secondary,
              input_val: action.data.countryCodeSecondary,
            },
            secondary_number: {
              ...state.form.contact_information.secondary_number,
              input_val: action.data.instanceSecondaryContact,
            },
            // authorized_contact: {
            //   ...state.form.contact_information.authorized_contact,
            //   input_val: action.data.companyAuthorizedContact
            // },
          },
          // bank_details: {
          //   ...state.form.bank_details,
          //   account_number: {
          //     ...state.form.bank_details.account_number,
          //     input_val: action.data.bankDetails.accountNumber
          //   },
          //   bank_name: {
          //     ...state.form.bank_details.bank_name,
          //     input_val: action.data.bankDetails.bankName
          //   },
          //   ifsc_code: {
          //     ...state.form.bank_details.ifsc_code,
          //     input_val: action.data.bankDetails.ifscCode
          //   },
          //   branch_name: {
          //     ...state.form.bank_details.branch_name,
          //     input_val: action.data.bankDetails.branchName
          //   },
          // },
          mailing_address: {
            ...state.form.mailing_address,
            address_line_1: {
              ...state.form.mailing_address.address_line_1,
              input_val: action.data.instanceAddress1,
            },
            address_line_2: {
              ...state.form.mailing_address.address_line_2,
              input_val: action.data.instanceAddress2,
            },
            city: {
              ...state.form.mailing_address.city,
              input_val: action.data.instanceCity,
            },
            state: {
              ...state.form.mailing_address.state,
              input_val: action.data.instanceState,
            },
            // country_name: {
            //   ...state.form.mailing_address.country_name,
            //   input_val: action.data.companyCountry
            // },
            zip_code: {
              ...state.form.mailing_address.zip_code,
              input_val: action.data.instancePincode,
            },
          },
        },
        companyFeatures: action.data.companyFeatures,
        companyAdmins: action.data.companyAdmins,
      };

    case companyActionTypes.SUCCESS_EDIT_COMPANY:
      return {
        ...state,
        form: {
          ...state.form,
          server_success: action.data,
        },
      };

    case companyActionTypes.SET_COMPANY_LOGO:
      return {
        ...state,
        form: {
          ...state.form,
          company_information: {
            ...state.form.company_information,
            company_logo: {
              ...state.form.company_information.company_logo,
              logoUrl: action.data,
            },
          },
        },
      };

    case companyActionTypes.SET_FAVICON:
      return {
        ...state,
        form: {
          ...state.form,
          company_information: {
            ...state.form.company_information,
            favicon: {
              ...state.form.company_information.favicon,
              faviconUrl: action.data,
            },
          },
        },
      };

    case companyActionTypes.SET_SIGNUP_IMAGE:
      return {
        ...state,
        form: {
          ...state.form,
          company_information: {
            ...state.form.company_information,
            signup_image: {
              ...state.form.company_information.signup_image,
              signupUrl: action.data,
            },
          },
        },
      };

    case companyActionTypes.CLEAR_ADD_COMPANY_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          company_information: {
            ...state.form.company_information,
            company_name: {
              ...state.form.company_information.company_name,
              input_val: "",
            },
            company_logo: {
              ...state.form.company_information.company_logo,
              logoUrl: "",
            },
            favicon: {
              ...state.form.company_information.favicon,
              faviconUrl: "",
            },
            signup_image: {
              ...state.form.company_information.signup_image,
              signupUrl: "",
            },
            // company_GST: {
            //   ...state.form.company_information.company_GST,
            //   input_val: ''
            // },
          },
          contact_information: {
            ...state.form.contact_information,
            email: {
              ...state.form.contact_information.email,
              input_val: "",
            },
            country_code_primary: {
              ...state.form.contact_information.country_code_primary,
              input_val: "129",
            },
            contact_number: {
              ...state.form.contact_information.contact_number,
              input_val: "",
            },
            country_code_secondary: {
              ...state.form.contact_information.country_code_secondary,
              input_val: "129",
            },
            secondary_number: {
              ...state.form.contact_information.secondary_number,
              input_val: "",
            },
            // authorized_contact: {
            //   ...state.form.contact_information.authorized_contact,
            //   input_val: ''
            // },
          },
          // bank_details: {
          //   ...state.form.bank_details,
          //   account_number: {
          //     ...state.form.bank_details.account_number,
          //     input_val: ''
          //   },
          //   bank_name: {
          //     ...state.form.bank_details.bank_name,
          //     input_val: ''
          //   },
          //   ifsc_code: {
          //     ...state.form.bank_details.ifsc_code,
          //     input_val: ''
          //   },
          //   branch_name: {
          //     ...state.form.bank_details.branch_name,
          //     input_val: ''
          //   },
          // },
          mailing_address: {
            ...state.form.mailing_address,
            address_line_1: {
              ...state.form.mailing_address.address_line_1,
              input_val: "",
            },
            address_line_2: {
              ...state.form.mailing_address.address_line_2,
              input_val: "",
            },
            city: {
              ...state.form.mailing_address.city,
              input_val: "",
            },
            state: {
              ...state.form.mailing_address.state,
              input_val: "",
            },
            // country_name: {
            //   ...state.form.mailing_address.country_name,
            //   input_val: ''
            // },
            zip_code: {
              ...state.form.mailing_address.zip_code,
              input_val: "",
            },
          },
          client_errors: [],
          server_errors: "",
          server_success: "",
        },
      };

    case companyActionTypes.RESET_COMPANY_ACCOUNT_DETAILS:
      return {
        ...state,
        resetCompanyAccountDetails: action.data,
      };

    default:
      return state;
  }
};
