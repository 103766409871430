import React from "react";

const FeedStar = ({ color, width, height }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.166748"
        width="17.5"
        height="17.5"
        rx="8.75"
        fill={color || "#1BC5BD"}
      />
      <path
        d="M8.91673 11.5337L6.07382 13.125L6.70867 9.9294L4.31641 7.71732L7.55191 7.33359L8.91673 4.375L10.2815 7.33359L13.517 7.71732L11.1248 9.9294L11.7596 13.125L8.91673 11.5337Z"
        fill="white"
      />
    </svg>
  );
};

export default FeedStar;
