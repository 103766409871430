import React from "react";
import {NavLink}  from "react-router-dom";

const MenuList = (props) => {
    const { child, appMenu, getMenuItemActive } = props;
    return (
        <>
            {child.submenu.length > 0 &&
            <li
                id={child.key}
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
                <NavLink className="menu-link menu-toggle" to={`/${child?.page || 'overview'}`}>
                    <span className="svg-icon menu-icon">
                        <i className={`${child.icon}`}></i>
                    </span>
                    <span className="menu-text">{child.title}</span>
                    <i className="menu-arrow"/>
                </NavLink>
                <div className="menu-submenu ">
                    <ul className="menu-subnav">
                        <li
                            className="menu-item  menu-item-parent"
                            aria-haspopup="true"
                        >
                            <span className="menu-link">
                                <span className="menu-text">{child.title}</span>
                            </span>
                        </li>
                        {/*begin::2 Level*/}
                        {
                            child.submenu.map((item, index) => {
                            return(
                                <>
                                {
                                    item.title && appMenu[child.key].subMenu[item.key] && (
                                        <li
                                            className={`menu-item ${getMenuItemActive(
                                                `/${item.page}`
                                            )}`}
                                            aria-haspopup="true"
                                        >
                                            <NavLink className="menu-link" to={`/${item.page}`}>
                                                <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                                </i>
                                                <span className="menu-text">{item.title}</span>
                                            </NavLink>
                                        </li>
                                    )
                                }
                                </>
                            )
                            })
                        }
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            // {/*end::1 Level*/}
            }
            { child.submenu.length === 0 &&
                <li
                className={`menu-item ${getMenuItemActive(
                    `${child.page}`, false
                )}`}
                aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={`/${child.page}`}>
                        <span className="svg-icon menu-icon">
                            <i className={`${child.icon}`}></i>
                        </span>
                        <span className="menu-text">{child.title}</span>
                    </NavLink>
                </li>
                // {/*end::2 Level*/}
            }
        </>
    )
}

export default MenuList;
