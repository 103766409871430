import {HTTPClient} from './http.service';

export const baggageService = {

  // ****** ZONE APIS
  async addZone(data) {
    try {
      const result = await HTTPClient.post(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/zone`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getZones (data) {
    try {
      let result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/zone`, data)
      return result;
    } catch (error) {
      throw error;
    }
  },
  async getZone(id) {
    try {
      const result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/zone/${id}`);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async updateZone(data, id) {
    try {
      const result = await HTTPClient.put(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/zone/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteZone(id) {
    try {
      const result = await HTTPClient.delete(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/zone/${id}`);
      return result;
    } catch(error) {
      throw error;
    }
  },

  // ****** BAGS APIS
  async addBag(data) {
    try {
      const result = await HTTPClient.post(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/bag`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getBags (data) {
    try {
      let result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/bag`, data)
      return result;
    } catch (error) {
      throw error;
    }
  },
  async getBag(id) {
    try {
      const result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/bag/${id}`);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async updateBag(data, id) {
    try {
      const result = await HTTPClient.put(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/bag/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteBag(id) {
    try {
      const result = await HTTPClient.delete(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/bag/${id}`);
      return result;
    } catch(error) {
      throw error;
    }
  },

  // Daily operations
  async getDailyOperations (data) {
    try {
      let result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/baggageHistory`, data)
      return result;
    } catch (error) {
      throw error;
    }
  },
  async getHistoryLogs (data) {
    try {
      let result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/baggageHistory/logs`, data)
      return result;
    } catch (error) {
      throw error;
    }
  },
  async getDetails () {
    try {
      let result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/baggageHistory/home`)
      return result;
    } catch (error) {
      throw error;
    }
  },
  async getStatus (id) {
    try {
      const result = await HTTPClient.get(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/baggageHistory/logs/${id}`);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async deleteAllPendingBags () {
    try {
      const result = await HTTPClient.put(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/release`);
      return result;
    } catch(error) {
      throw error;
    }
  },

  // REQUEST OTP
  async requestOtp (data) {
    try {
      const result = await HTTPClient.post(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/requestOtp`, data);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async sendOTP (data) {
    try {
      const result = await HTTPClient.post(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/verifyOtp`, data);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async releaseBagNow (data, id) {
    try {
      const result = await HTTPClient.put(`${process.env.REACT_APP_BAGGAGE_CONST_URL}/baggage/release/${id}`, data);
      return result;
    } catch(error) {
      throw error;
    }
  }
}