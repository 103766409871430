import {HTTPClient} from './http.service';

export const smsSettings = {
  async getSmsList(mallId, params) {
    try {
      const result = await HTTPClient.get(`/settings/smsGateway/${mallId}`, params);
      return result;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async addSms(mallId, data) {
    try {
      const response = await HTTPClient.post(`/settings/smsGateway/${mallId}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async getSmsDetails(mallId, smsId) {
    try {
      const result = await HTTPClient.get(`/settings/smsGateway/${mallId}/${smsId}`);
      return result;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async editSms(mallId, smsId, data) {
    try {
      const result = await HTTPClient.put(`/settings/smsGateway/${mallId}/${smsId}`, data);
      return result;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async getPartnerConfiguration(mallId) {
    try {
      const result = await HTTPClient.get(`/settings/getPartnerConfiguration/${mallId}`);
      return result;
    } catch(e) {
      return Promise.reject(e);
    }
  }
}
