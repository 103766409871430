import React from "react";
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { malls_switch_init } from "../../store/modules_mallsStore/mm.actions";

const MallList = (props) => {
  const dispatch = useDispatch();

  const mallList = useSelector(
    ({ modulesAndMalls }) => modulesAndMalls.mallsList
  );
  const selectedMall = useSelector(
    ({ modulesAndMalls }) => modulesAndMalls.selectedMall
  );

  const handleMallSelection = (id) => {
    props.handleMallChange(id);
    dispatch(malls_switch_init(id));
    props.history.push("/");
  };

  const getInitials = (string) => {
    // console.log("string is ", string);
    if (!string) {
      return;
    }
    let names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <>
      {mallList &&
        mallList.map((item) => {
          return (
            <li
              className="nav-item mb-3"
              data-toggle="tooltip"
              data-placement="rigth"
              data-container="body"
              data-boundary="window"
              title={item.mallName}
              key={item.mallId}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="metronic-features">{item.mallName}</Tooltip>
                }
              >
                {/* eslint-disable-next-line */}
                <a
                  className={`nav-link btn btn-icon btn-clean btn-lg ${selectedMall ===
                    item.mallId && "active"}`}
                  onClick={() => handleMallSelection(item.mallId)}
                  role="tab"
                >
                  <span className="malls-logo">
                    {getInitials(item.mallName)}
                  </span>
                </a>
              </OverlayTrigger>
            </li>
          );
        })}
    </>
  );
};

export default withRouter(MallList);
