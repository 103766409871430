import { takeLatest, call, all, put, select } from "redux-saga/effects";
import { modules_success, malls_switch_success } from "./mm.actions";
import { mmActiontypes } from "./mm.actionTypes";
import { appService, HTTPClient } from "../../service";
import { appConst_init } from "../appStore/appStore.action";

export function* getInitModules() {
  const mallId = select((state) => state.modulesAndMalls.selectedMall);
  // if(mallId){
  try {
    const modulesList = yield appService.getModulesList();
    const menuObj = yield appService.getMenuObj();
    yield put(modules_success({ modulesList, menuObj }));
    yield put(appConst_init());
    return modulesList;
  } catch (e) {
    console.log(e);
  }
  // }
  // else {
  //   return []
  // }
}

export function* modulesInit() {
  yield takeLatest(mmActiontypes.MODULES_INIT, getInitModules);
}

export function* mallSwitchEvent({ payload: mallId }) {
  try {
    yield HTTPClient.saveHeader({ key: "instanceId", value: `${mallId}` });
    yield* getInitModules();
    yield put(malls_switch_success());
  } catch (e) {
    console.log(e);
  }
}

export function* mallSwithInit() {
  yield takeLatest(mmActiontypes.MALL_SWITCH_INIT, mallSwitchEvent);
}

export function* mmSaga() {
  yield all([call(modulesInit), call(mallSwithInit)]);
}
