import React, {useEffect} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as queryString  from 'query-string';
import {register_start} from '../../store/auth/auth.action';
import {showToastMessage} from '../../store/appStore/appStore.action';
import {authService} from '../../service/api/auth.service';
import clsx from "clsx";
// import * as auth from "../../store/ducks/auth.duck";
// import { register } from "../../crud/auth.crud";

function Registration({match, location, history, registerUser, showToast}) {
  const {e: email, k: token} = queryString.parse(location.search);
  useEffect(() => {
    if(!email && !token) {
      history.push('/auth')
    } else {
      checkLink()
    }
  },[]); // eslint-disable-line react-hooks/exhaustive-deps
  const checkLink = async () => {
    try {
      await authService.checkLinkExp({email, key: token})
    }catch(e) {
      history.push('/auth')
    }
  }
  return (
    <div className="kt-login__body loginForm">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3 className="font-size-h1 title">
            Set Up Account
          </h3>
        </div>

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            mobile: '',
            password: "",
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};

            // if(!values.firstName) {
            //   errors.firstName = "First Name is Required";
            // } else if(!/^[A-Za-z]+$/i.test(values.firstName)) {
            //   errors.firstName = "First Name Should be character values";
            // }else if(values.firstName.length > 25) {
            //   errors.firstName = "First Name exceeds 25 Character";
            // }
            // if(!values.lastName) {
            //   errors.lastName = "Last Name is Required";
            // } else if(!/^[A-Za-z]+$/i.test(values.lastName)) {
            //   errors.lastName = "Last Name Should be character values";
            // }else if(values.lastName.length > 25) {
            //   errors.lastName = "Last Name exceeds 25 Character";
            // }
            // if(!values.mobile) {
            //   errors.mobile = 'Mobile number is Required';
            // } else if(!/^([7-9][0-9]{9})$/.test(values.mobile)) {
            //   errors.mobile = 'Invalid Mobile number';
            // }

            if (!values.password) {
              errors.password = 'Password is Required';
            }else if(values.password.length > 25) {
              errors.password = "Pasword length exceed 25 character";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = 'Confirm Password is Required';
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }else if(values.confirmPassword.length > 25) {
              errors.password = "Confirm Pasword length exceed 25 character";
            }
            return errors;
          }}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            try {
              await authService.registerUser({
                email,
                verificationCode: token,
                setPassword: values.password,
                confirmPassword: values.confirmPassword,
                // firstName: values.firstName,
                // lastName: values.lastName,
                // mobile: values.mobile
              });
              showToast({type: 'success', message: 'User Is Successfuly Registered'});
              history.push('/auth');
            }catch(e) {
              console.log(e);
            }
            // registerUser();
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}
{
              // <div className="form-group">
              //   <input
              //     type="text"
              //     placeholder="First Name *"
              //     className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.firstName})}`}
              //     name="firstName"
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     value={values.firstName}
              //     helperText={touched.firstName && errors.firstName}
              //     error={Boolean(touched.firstName && errors.firstName)}
              //   />
              //   <div className="fv-plugins-message-container">
              //     <div className="fv-help-block">{errors.firstName}</div>
              //   </div>
              // </div>
              // <div className="form-group">
              //   <input
              //     type="text"
              //     placeholder="Last Name *"
              //     className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.lastName})}`}
              //     name="lastName"
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     value={values.lastName}
              //     helperText={touched.lastName && errors.lastName}
              //     error={Boolean(touched.lastName && errors.lastName)}
              //   />
              //   <div className="fv-plugins-message-container">
              //     <div className="fv-help-block">{errors.lastName}</div>
              //   </div>
              // </div>
              // <div className="form-group">
              //   <input
              //     type="number"
              //     placeholder="Mobile Number *"
              //     className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.mobile})}`}
              //     name="mobile"
              //     onBlur={handleBlur}
              //     onChange={handleChange}
              //     value={values.mobile}
              //     helperText={touched.mobile && errors.mobile}
              //     error={Boolean(touched.mobile && errors.mobile)}
              //     maxLength="10"
              //   />
              //   <div className="fv-plugins-message-container">
              //     <div className="fv-help-block">{errors.mobile}</div>
              //   </div>
              // </div>
              }
              <div className="form-group">
                <input
                  type="password"
                  placeholder="Password *"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.password})}`}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.password}</div>
                </div>
              </div>

              <div className="form-group">
                <input
                  type="password"
                  placeholder="Confirm Password *"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.confirmPassword})}`}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.confirmPassword}</div>
                </div>
              </div>

              <div className="kt-login__actions centerActions">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn btn-primary btn-elevate kt-login__btn-primary"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  registerUser: payload => dispatch(register_start(payload)),
  showToast: payload => dispatch(showToastMessage(payload))
})
export default connect(null, mapDispatchToProps)(Registration);
