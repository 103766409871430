import React, { useEffect } from "react";
import TopHeader from "./TopHeader";
import HomeBanner from "./HomeBanner";
import "./style.scss";
import "./mobilePreview.scss";
import ViewAllIcon from "../assets/view-all.svg";
import CouponBg from "../assets/coupon.svg";
import CoinIcon from "../assets/coupon-coin.svg";
import RightChevron from "../assets/RightChevron";
import Slider1 from "../assets/slider-1.svg";
import Slider2 from "../assets/slider-2.svg";
import Slider3 from "../assets/slider-3.svg";
import FeedProfile from "../assets/feed-profile.svg";
import FeedStar from "../assets/FeedStar";
import PostMedia from "../assets/post-media.svg";
import PostShare from "../assets/post-share.svg";
import PostLike from "../assets/PostLike";
import Footer from "./Footer";
import WebFont from "webfontloader";

const playAndWinData = [
  {
    id: 1,
    title: "Spin the wheel",
    text: "Try your luck and win",
    cta: "Spin Now",
    // background: PlayBg1,
  },
  {
    id: 2,
    title: "Scratch Card",
    text: "Win exciting gifts",
    cta: "Get your Card",
    // background: PlayBg2,
  },
  {
    id: 3,
    title: "What’s your opinion? ",
    text: "Fill surveys & get gifts",
    cta: "Fill a Survey",
    // background: PlayBg3,
  },
];

const sliderImages = [
  {
    id: 1,
    img: Slider1,
  },
  {
    id: 2,
    img: Slider2,
  },
  {
    id: 3,
    img: Slider3,
  },
];

const fontWeights = [
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
];

const Preview = ({ formData, isPreviewDesktop, isPreviewModal }) => {
  const borderRadius = "12px";

  const getMobileHeaderStyle = () => {
    if (!isPreviewDesktop) {
      let backgroundColor = formData.mobileBannerImage?.preview
        ? ""
        : formData.defaultHeaderBackground;
      let backgroundImage = `url(${formData.mobileBannerImage.preview})`;

      return {
        backgroundColor,
        backgroundImage,
        backgroundSize: "cover",
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    loadFont(
      formData.fontFamily,
      fontWeights,
      () => {},
      () => {
        console.error(`Failed to load font ${formData.fontFamily}`);
      }
    );
  }, [formData.fontFamily]);

  const loadFont = (fontFamily, weights, onLoad, onError) => {
    WebFont.load({
      google: {
        families: [`${fontFamily}:${weights.join(",")}`],
      },
      active: () => {
        if (onLoad) onLoad();
      },
      inactive: () => {
        console.error(`Font ${fontFamily} could not be loaded.`);
        if (onError) onError();
      },
    });
  };

  return (
    <div
      className={`preview-page ${
        isPreviewDesktop ? "" : "preview-page-mobile"
      }`}
      style={{ fontFamily: `"${formData.fontFamily}",sans-serif` }}
    >
      <div className="mobile-header" style={getMobileHeaderStyle()}>
        <TopHeader isPreviewDesktop={isPreviewDesktop} formData={formData} />
        <HomeBanner
          formData={formData}
          borderRadius={borderRadius}
          isPreviewDesktop={isPreviewDesktop}
        />
      </div>
      <div className="body">
        {/* Coupon section */}
        <div className="coupon-ctn">
          <div className="coupon-header">
            <div className="title">Coupons to explore</div>
            <div className="view-all">
              <span>View All</span>
              <img src={ViewAllIcon} alt="view all" className="right-icon" />
            </div>
          </div>
          <div className="coupons-wrapper">
            {[1, 2, 3].map((item) => (
              <div className="coupon" key={item}>
                <div
                  className="coupon-img-ctn"
                  style={{ borderRadius: formData.isRounded ? "12px" : "0" }}
                >
                  <img src={CouponBg} alt="coupon" className="coupon-img" />
                </div>
                <div className="coupon-data">
                  <span className="title">
                    10% off on shopping worth 3000 & above
                  </span>
                  <div className="store-points-ctn">
                    <span className="store-name">store-name</span>
                    <span className="points-ctn">
                      <div className="coin-img-ctn">
                        <img src={CoinIcon} className="coin-img" alt="points" />
                      </div>
                      <span
                        className="points-text"
                        style={{ color: formData.colour }}
                      >
                        100 Points
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!isPreviewDesktop && (
            <div className="explore-coupons" style={{ color: formData.colour }}>
              Explore coupons
              <RightChevron color={formData.colour} />
            </div>
          )}
        </div>
        {/* Play and win section */}
        <div className="play-win-ctn">
          <div className="title">Play and Win</div>
          <div className="card-ctn">
            {playAndWinData.map((item) => (
              <div
                className="card"
                key={item.id}
                style={{ borderRadius: formData.isRounded ? borderRadius : 0 }}
              >
                <span className="title">{item.title}</span>
                <span className="text">{item.text}</span>
                <div className="cta-ctn">
                  <span>{item.cta}</span>
                  <RightChevron color={"white"} />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Slider */}
        <div className="slider-ctn">
          {sliderImages.map((item) => (
            <div
              className="slider"
              key={item.id}
              style={{ borderRadius: formData.isRounded ? borderRadius : 0 }}
            >
              <img src={item.img} alt="slider" className="slider-img" />
            </div>
          ))}
        </div>
        <div className="dots-ctn">
          <div
            className="dot"
            style={{ backgroundColor: formData.colour }}
          ></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        {/* Feed Post */}
        <div className="feed-ctn">
          {!isPreviewDesktop && <div className="feed-title">Feed</div>}
          {[1, 2, 3].map((item) => (
            <div className="feed" key={item}>
              <div className="feed-header">
                <div className="profile-img-ctn">
                  <img
                    src={FeedProfile}
                    className="profile-img"
                    alt="feed profile"
                  />
                </div>
                <div className="title-ctn">
                  <div className="title">Lorem Ipsum</div>
                  <div className="title-data">
                    <span>Category</span>
                    <span>.</span>
                    <span>Reward</span>
                  </div>
                </div>
                <div className="star-ctn">
                  <FeedStar
                    color={formData.colour}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
              <div className="post-title">
                You can add the header here for the respective company/brand.
              </div>
              <p className="post-text">
                Check out our entire collection, curated especially for you.
                Lorem...{" "}
                <span className="read-more" style={{ color: formData.colour }}>
                  read more
                </span>
              </p>
              <div
                className="post-media-ctn"
                style={{ borderRadius: formData.isRounded ? borderRadius : 0 }}
              >
                <img src={PostMedia} alt="post" className="post-media" />
              </div>
              <div className="post-dots-ctn">
                <div
                  className="dot"
                  style={{ backgroundColor: formData.colour }}
                ></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div className="post-footer">
                <div className="like-ctn">
                  <div className="like-img-ctn">
                    <PostLike
                      color={formData.colour}
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="like-text">You and 84 others liked this</div>
                </div>
                <div className="post-share-ctn">
                  <img src={PostShare} alt="share" className="post-share" />
                </div>
              </div>
            </div>
          ))}
          {!isPreviewDesktop && (
            <div className="explore-feed" style={{ color: formData.colour }}>
              Explore Feed
              <RightChevron color={formData.colour} />
            </div>
          )}
        </div>
      </div>
      <Footer formData={formData} isPreviewDesktop={isPreviewDesktop} />
    </div>
  );
};

export default Preview;
