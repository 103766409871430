import { HTTPClient, HTTPClientV2 } from "./http.service";
var qs = require("qs");

export const referralService = {
  async getList(params) {
    try {
      let result = await HTTPClient.get(`/referral/`, params);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async getCampaignDetails(campaignId) {
    try {
      let result = await HTTPClient.get(`/referral/${campaignId}`);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async addCampaign(data) {
    try {
      let result = HTTPClient.customRequest({
        url: `/referral/`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async deleteCampaign(campaignId) {
    try {
      let result = await HTTPClient.delete(`/referral/${campaignId}`);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async updateCampaign(data, campaignId) {
    try {
      let result = HTTPClient.customRequest({
        url: `/referral/${campaignId}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async getCode() {
    try {
      let result = await HTTPClient.get(`/referral/code`);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async getUserList(campaignId, params) {
    try {
      let result = await HTTPClientV2.get(
        `/referral/users/${campaignId}`,
        params
      );
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
  async couponList() {
    try {
      const result = await HTTPClient.get(
        "/coupon/?sort=desc&status=active&category=2"
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  async rewardList() {
    try {
      const result = await HTTPClient.get(
        "/rewards/?sort=desc&status=2&category=2"
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
