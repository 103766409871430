import {HTTPClient} from './http.service';

export const companyService = {
  async addCompany(data) {
    try {
      let result = await HTTPClient.post('/settings/instance', data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getCompanyById (id) {
    try {
      let result = await HTTPClient.get(`/settings/instance/${id}`)
      return result
    } catch (error) {
      throw error;
    }
  },
  async editCompany(data, id) {
    try {
      let result = await HTTPClient.put(`/settings/instance/${id}`, data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getCompanies (data) {
    try {
      let result = await HTTPClient.get('/settings/instance/list/', data)
      return result
    } catch (error) {
      throw error;
    }
  },
  async getCompanyMeta(id) {
    try {
      let {data:{data: companyMeta}} = await HTTPClient.get(`/company/companyInfo/${id}`);
      return companyMeta;
    } catch(e) {
      return e;
    }
  },
  async getTechnicalInfo (id) {
    try {
      let result = await HTTPClient.get(`/company/technicalInfo/getInfo/${id}`)
      return result
    } catch (error) {
      throw error;
    }
  },
  async addTechnicalData(data) {
    try {
      let result = await HTTPClient.post('/company/technicalInfo/addInfo', data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async updateTechnicalData(data, id) {
    try {
      let result = await HTTPClient.put(`/company/technicalInfo/updateInfo/${id}`, data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  }
}
