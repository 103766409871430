import React from "react";
import GoldTier from "../assets/gold-tier.svg";
import RightIcon from "../assets/ic-chevron-right.svg";

const HomeBanner = ({ formData, isPreviewDesktop, borderRadius }) => {
  const getBackgroundImage = () => {
    if (isPreviewDesktop) {
      return `url(${formData.desktopBannerImage?.preview})`;
    } else {
      return `url(${formData.mobileBannerImage?.preview})`;
    }
  };

  const getBannerStyle = () => {
    if (isPreviewDesktop) {
      return {
        backgroundColor: formData.desktopBannerImage?.preview
          ? ""
          : formData.defaultHeaderBackground,
        backgroundImage: `url(${formData.desktopBannerImage?.preview})`,
        backgroundSize: "cover",
      };
    } else {
      return {};
    }
  };

  return (
    <div className="home-banner" style={getBannerStyle()}>
      <div className="title">Home</div>
      <div
        className="tier-ctn"
        style={{ borderRadius: formData.isRounded ? borderRadius : "0" }}
      >
        <img src={GoldTier} alt="gold tier" className="tier-img" />
        <div className="tier-data">
          <div className="tier-info">
            <span className="tier-name">Gold Tier</span>
            <span>|</span>
            <span className="tier-points">3,244 points</span>
          </div>
          <div className="tier-border">
            <div
              className="tier-border-active"
              style={{ backgroundColor: formData.colour }}
            ></div>
          </div>
        </div>
        <img
          src={RightIcon}
          alt="chevron right"
          className="right-chevron-img"
        />
      </div>
    </div>
  );
};

export default HomeBanner;
