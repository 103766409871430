import React from "react";

const DesktopView = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons/ic-coin-filled">
        <path
          id="computer"
          d="M5.33317 18C5.14428 18 4.98595 17.9361 4.85817 17.8083C4.73039 17.6806 4.6665 17.5222 4.6665 17.3333C4.6665 17.1444 4.73039 16.9861 4.85817 16.8583C4.98595 16.7306 5.14428 16.6667 5.33317 16.6667H18.6665C18.8554 16.6667 19.0137 16.7306 19.1415 16.8583C19.2693 16.9861 19.3332 17.1444 19.3332 17.3333C19.3332 17.5222 19.2693 17.6806 19.1415 17.8083C19.0137 17.9361 18.8554 18 18.6665 18H5.33317ZM6.6665 16C6.29984 16 5.98595 15.8694 5.72484 15.6083C5.46373 15.3472 5.33317 15.0333 5.33317 14.6667V7.33333C5.33317 6.96667 5.46373 6.65278 5.72484 6.39167C5.98595 6.13056 6.29984 6 6.6665 6H17.3332C17.6998 6 18.0137 6.13056 18.2748 6.39167C18.5359 6.65278 18.6665 6.96667 18.6665 7.33333V14.6667C18.6665 15.0333 18.5359 15.3472 18.2748 15.6083C18.0137 15.8694 17.6998 16 17.3332 16H6.6665ZM6.6665 14.6667H17.3332V7.33333H6.6665V14.6667Z"
          fill={color || "#000"}
        />
      </g>
    </svg>
  );
};

export default DesktopView;
