import React from "react";

const RightChevron = ({ color }) => {
  return (
    <svg
      width={"4.4px"}
      height={"4.4px"}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="right-chevron"
    >
      <path
        d="M5.69238 5.00006L3.62988 2.93756L4.21905 2.34839L6.87072 5.00006L4.21905 7.65172L3.62988 7.06256L5.69238 5.00006Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export default RightChevron;
