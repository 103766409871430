import { HTTPClient } from "./http.service";

export const cashbackService = {
  async getCashback() {
    try {
      const response = await HTTPClient.get(`/cashbackPayouts`);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async putCashback(data) {
    try {
      let result = HTTPClient.put(`/cashbackPayouts`, data);
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
};
