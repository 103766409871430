/* eslint-disable */
import { formateNameKey } from "./common";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validGSTIN = RegExp(
  /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/
);
const validIFSCCode = RegExp(/^[A-Za-z]{4}\d{7}$/);
const validateAlphaNumeric = RegExp(/^[a-zA-Z0-9-, ]*$/);
const validateAccessKey = RegExp(/^[a-zA-Z0-9]{32}$/);

export const stringValidation = async (key, value, required, min, max) => {
  if (value || required) {
    if (required && !value.trim()) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    if (key === "company_GST") {
      if (!validGSTIN.test(value)) {
        return {
          key: key,
          msg: `${formateNameKey(key)} should be in proper format`,
        };
      }
    }
    if (key === "ifsc_code") {
      if (!validIFSCCode.test(value)) {
        return {
          key: key,
          msg: `${formateNameKey(key)} should be in proper format`,
        };
      }
    }
    if (key === "access_key") {
      if (!validateAccessKey.test(value)) {
        return {
          key: key,
          msg: `${formateNameKey(
            key
          )} should be 32 characters in aplha numeric`,
        };
      }
    }
    if (typeof value === "string") {
      if (!validateAlphaNumeric.test(value)) {
        return {
          key: key,
          msg: `${formateNameKey(key)} should be character and letters only`,
        };
      }
      if (!(value.length >= min && value.length <= max)) {
        if (min === max) {
          return {
            key: key,
            msg: `${formateNameKey(key)} should be ${min} characters long`,
          };
        }
        return {
          key: key,
          msg: `${formateNameKey(
            key
          )} should be between ${min} and ${max} characters long`,
        };
      }
      return "";
    } else {
      return {
        key: key,
        msg: `${formateNameKey(key)} should be a characters`,
      };
    }
  } else {
    return "";
  }
};

export const numberValidation = async (key, value, required, min, max) => {
  let number = value ? Number(value) : "";
  if (number || required) {
    if (required && !number) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    if (typeof number === "number") {
      let data = number;
      if (!(data.toString().length >= min && data.toString().length <= max)) {
        if (min === max) {
          return {
            key: key,
            msg: `${formateNameKey(key)} should be ${min} digits long`,
          };
        }
        return {
          key: key,
          msg: `${formateNameKey(
            key
          )} should be between ${min} and ${max} digits long`,
        };
      }
      return "";
    } else {
      return {
        key: key,
        msg: `${formateNameKey(key)} should be a number`,
      };
    }
  } else {
    return "";
  }
};

export const emailValidation = (key, value, required) => {
  if (value || required) {
    if (required && !value) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    if (validEmailRegex.test(value)) {
      return "";
    } else {
      return {
        key: key,
        msg: `${formateNameKey(key)} should be a valid`,
      };
    }
  } else {
    return "";
  }
};

export const imageValidation = (
  key,
  value,
  required,
  size,
  dimensions,
  image_type
) => {
  if (required || value) {
    if (!value) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    // Checking image size, in mb
    if (size * 1000 * 1000 <= value.size) {
      return {
        key: key,
        msg: `${formateNameKey(key)} size must not be greater than ${size} mb`,
      };
    }

    if (!value.name.match(/\.(png|jpeg|jpg|svg)$/)) {
      return {
        key: key,
        msg: `${formateNameKey(
          key
        )} image file should be only of jpg, jpeg, png`,
      };
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const videoValidation = (
  key,
  value,
  required,
  size,
  dimensions,
  image_type
) => {
  if (required || value) {
    if (!value) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    // Checking image size, in mb
    if (size * 1000 * 1000 <= value.size) {
      return {
        key: key,
        msg: `${formateNameKey(key)} size must not be greater than ${size} mb`,
      };
    }

    if (!value.name.match(/\.(mp4)$/)) {
      return {
        key: key,
        msg: `${formateNameKey(
          key
        )} image file should be only of jpg, jpeg, png`,
      };
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const ImageGifValidation = (
  key,
  value,
  required,
  size,
  dimensions,
  image_type
) => {
  if (required || value) {
    if (!value) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    }
    // Checking image size, in mb
    if (size * 1000 * 1000 <= value.size) {
      return {
        key: key,
        msg: `${formateNameKey(key)} size must not be greater than ${size} mb`,
      };
    }

    if (!value.name.match(/\.(png|jpeg|jpg|gif)$/)) {
      return {
        key: key,
        msg: `${formateNameKey(
          key
        )} image file should be only of jpg, jpeg, png, gif`,
      };
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const selectValidation = (key, value, required) => {
  if (required || value) {
    if (!value) {
      return {
        key: key,
        msg: `${formateNameKey(key)} is required`,
      };
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const validateFinally = async (form) => {
  let errors = [];
  for (const [key, value] of Object.entries(form)) {
    if (key === "client_errors" || key === "server_errors") {
      // should be the last two keys in the object so every
      break; // other keys can be iterated
    }
    for (const [key1, value1] of Object.entries(value)) {
      let error = {};
      if (value1.type.name === "String") {
        error = await stringValidation(
          key1,
          value1.input_val,
          value1.required,
          value1.condition.min,
          value1.condition.max
        );
      } else if (value1.type.name === "Number") {
        error = await numberValidation(
          key1,
          value1.input_val,
          value1.required,
          value1.condition.min,
          value1.condition.max
        );
      } else if (value1.type.name === "Email") {
        error = await emailValidation(
          key1,
          value1.input_val,
          value1.required,
          value1.condition.min,
          value1.condition.max
        );
      } else if (value1.type.name === "File") {
        error = await imageValidation(
          key1,
          value1.input_val,
          value1.required,
          value1.condition.size
        );
      } else if (value1.type.name === "Select") {
        error = await selectValidation(key1, value1.input_val, value1.required);
      }
      if (error) errors.push(error);
    }
  }
  if (errors.length > 0) {
    let error_object = {
      error_type: "CLIENT",
      errors,
    };
    throw error_object;
  }
  return errors;
};

export const booleanValidation = (key, value, required) => {
  if (required || value) {
    // if (!value) {
    //   return {
    //     key: key,
    //     msg: `${formateKeyName(key)} is required`
    //   }
    // }
    if (typeof value !== "boolean") {
      return {
        key: key,
        msg: `${formateKeyName(key)} should be either true or false`,
      };
    }
    return "";
  } else {
    return "";
  }
};

export const validateFinally1 = async (form) => {
  let errors = [];
  for (const [key, value] of Object.entries(form)) {
    let error = {};
    if (value.type.name === "String") {
      error = await stringValidation(
        key,
        value.input_val,
        value.required,
        value.condition.min,
        value.condition.max
      );
    } else if (value.type.name === "Number") {
      error = await numberValidation(
        key,
        value.input_val,
        value.required,
        value.condition.min,
        value.condition.max
      );
    } else if (value.type.name === "Boolean") {
      error = await booleanValidation(
        key,
        value.input_val,
        value.required,
        value.condition.min,
        value.condition.max
      );
    } else if (value.type.name === "Email") {
      error = await emailValidation(
        key,
        value.input_val,
        value.required,
        value.condition.min,
        value.condition.max
      );
    } else if (value.type.name === "File") {
      error = await imageValidation(
        key,
        value.input_val,
        value.required,
        value.condition.size
      );
    } else if (value.type.name === "Select") {
      error = await selectValidation(key, value.input_val, value.required);
    }
    console.log(key, error);
    if (error) errors.push(error);
  }
  if (errors.length > 0) {
    let error_object = {
      error_type: "CLIENT",
      errors,
    };
    throw error_object;
  }
  return errors;
};
