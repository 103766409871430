import React, { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import "./style.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { HTTPClient } from "../../service";

const NewDashboardDemo = () => {
  const location = useLocation();
  const state = location.state;
  const bearerToken = HTTPClient.getHeader("Authorization");
  const mallId = HTTPClient.getMallId();

  useEffect(() => {
    const embed = async () => {
      await embedDashboard({
        supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN,
        // id: process.env.REACT_APP_SUPERSET_ID, // given by the Superset embedding UI
        id: state.resourceId,
        mountPoint: document.getElementById("dashboard"), // html element in which iframe render
        fetchGuestToken: () => getToken(),
        debug: true,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: true,
          filters: {
            expanded: false,
          },
        },
      });
    };
    if (document.getElementById("dashboard")) {
      embed();
    }
  }, []);

  const getToken = async () => {
    let data = {
      username: "test",
      password: "test",
      provider: "db",
      refresh: true,
    };
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: 'https://stage-super-analytics.keubik.in/api/v1/security/login',
      url: `${process.env.REACT_APP_CONST_URL}/internalApps/superset?instanceId=${mallId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      // withCredentials: true,
      resources: [
        {
          type: "dashboard",
          // id: "eaaebe79-e864-4b8c-afe1-aad81f05ace1",
          id: state.resourceId,
        },
      ],
      data: data,
    };
    const token = await axios.request(config);
    return token.data;
  };
  return (
    <div>
      <div id="dashboard" />
    </div>
  );
};

export default NewDashboardDemo;
