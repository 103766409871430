import {HTTPClient} from './http.service';

export const controllerAppService = {
	async getModuleVersions() {
		try {
			let result = await HTTPClient.get('/moduleVersion/getVersion/');
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getModules() {
		try {
			return new Promise(function(resolve, reject) {
				resolve({status: 200, data: {}});
			});
			let result = await HTTPClient.get('/moduleVersion/getModules/');
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getSubModules(id, version_id) {
		try {
			let result = await HTTPClient.get(`/moduleVersion/getSubModules/${id}?version=${version_id}`);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async setModulesVersion(data) {
		try {
			let result = await HTTPClient.post('/moduleVersion/setModuleVersion', data);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async editModulesVersion(data) {
		try {
			let result = await HTTPClient.post('/moduleVersion/editModuleVersion', data);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getVersions () {
    try {
			let result = await HTTPClient.get('/moduleVersion/getVersionList');
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getVersionsByCompanyId (id) {
    try {
			let result = await HTTPClient.get(`/moduleVersion/getCompanyVersionList/${id}`);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getAllModulesByVersion (id, isChanged) {
    try {
			let result = await HTTPClient.get(`/moduleVersion/getAllModuleList/?versionId=${id}`);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getEditModulesByVersion (id, isChanged) {
    try {
      let url = '';
      if (!isChanged) {
        url = `/moduleVersion/getModuleList/?versionId=${id}`;
      } else {
        url = `/moduleVersion/getModuleList/?versionId=${id}&isChanged=${isChanged}`
      }
			let result = await HTTPClient.get(url);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async getCompanyModuleList (companyId, versionId) {
    try {
			let result = await HTTPClient.get(`/moduleVersion/getCompanyModuleList/${companyId}?versionId=${versionId}`);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async setCompanyModulesVersion (data) {
    try {
			let result = await HTTPClient.post(`/moduleVersion/setCompanyModules/${data.companyId}`, data.permissionData);
			return result;
		} catch (error) {
			throw error;
		}
  },
  async setCompanyMallModulesVersion (companyId, mallId, data) {
    try {
			let result = await HTTPClient.post(`/moduleVersion/setMallModules/${companyId}/?companyId=${mallId}`, data);
			return result;
		} catch (error) {
			throw error;
		}
  }
}
