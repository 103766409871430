import { takeLatest, put, all, call, takeEvery } from 'redux-saga/effects';
import { categoryActionTypes } from './category.actiontype';
import { retailerCategoryService } from '../../../service';
import { actions } from './category.action';

function* handleAddCategory ({data, history}) {
  try {
    yield put(actions.addRequest())
    let result = yield retailerCategoryService.addCategory(data)
    let message = 'Category has been added.'
    yield put(actions.addSuccess(message))
    return result;
  } catch (error) {
    yield put(actions.setError(error))
  } finally {
    yield put(actions.addComplete())
  }
}

function* handleGetCategories ({data}) {
  try {
    yield put(actions.request())
    let result = yield retailerCategoryService.getCategories(data)
    yield put(actions.getCategories(result.data.data))
    return result
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetCategoryById ({id}) {
  try {
    let result = yield retailerCategoryService.getCategoryById(id)
    yield put(actions.getCategoryById(result.data.data))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleUpdateCategory ({data, id, temp}) {
  try {
    let result = yield retailerCategoryService.updateCategory(data, id)
    yield put(actions.updateCategory({...temp, id}))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleDeleteCategory ({id}) {
  try {
    let result = yield retailerCategoryService.deleteCategory(id)
    yield put(actions.deleteCategory(id))
    return result
  } catch (error) {
    console.log(error)
  }
}

export function* addRetailerCategory() {
  yield takeLatest(categoryActionTypes.WATCH_ADD_RETAILER_CATEOGORY, handleAddCategory)
}

export function* getRetailersCategories() {
  yield takeEvery(categoryActionTypes.WATCH_GET_RETAILER_CATEOGORIES, handleGetCategories)
}

export function* getRetailersCategoryById() {
  yield takeLatest(categoryActionTypes.WATCH_GET_RETAILER_CATEGORY_BY_ID, handleGetCategoryById)
}

export function* updateRetailerCategory() {
  yield takeLatest(categoryActionTypes.WATCH_UPDATE_RETAILER_CATEOGORY, handleUpdateCategory)
}

export function* deleteRetailerCategory() {
  yield takeLatest(categoryActionTypes.WATCH_DELETE_RETAILER_CATEOGORY, handleDeleteCategory)
}

export function* retailerCategorySaga() {
  yield all([
    call(addRetailerCategory),
    call(getRetailersCategories),
    call(getRetailersCategoryById),
    call(updateRetailerCategory),
    call(deleteRetailerCategory)
  ]);
}