import { takeLatest, takeEvery, put, select, all, call } from 'redux-saga/effects';
import { stringValidation, numberValidation, emailValidation, imageValidation, selectValidation } from '../../utils/inputValidations';
import { validateFinally } from '../../utils/inputValidations';
import { actions } from './mall.action';
import { mallActionTypes } from './mall.actiontype';
import { mallService } from '../../service';
import { convertFileToBase64 } from '../../utils/common'
import { serverToClientKeyValueMapping } from './mall.initial_state';

function* mapAddCompanyKeyValue (data) {
  const { mall_information } = data;
  const { base64 } = yield convertFileToBase64(mall_information.mall_logo.input_val);
  let mall_object = {};
  for (const [key, value] of Object.entries(serverToClientKeyValueMapping)) {
    for (const [key1, value1] of Object.entries(value)) {
      if (key1 !== 'mallLogo') {
        if (data[key][value1].type.name === "Number" || data[key][value1].type.name === "Select") {
          mall_object[key1] = data[key][value1].input_val ? Number(data[key][value1].input_val) : null;
        } else {
          mall_object[key1] = data[key][value1].input_val;
        }
      }
    }
  }

  if (base64) {
    mall_object.mallLogo = base64;
  }
  console.log(mall_object)
  return mall_object;
}

function* handleInputString ({object}) {
  const { target, initialState, key } = object;
  const { required, condition, type } = initialState;
  let min, max, size, dimensions, image_type;
  if (type.name !== 'File') {
    min = condition.min;
    max = condition.max;
  } else {
    size = condition.size;
    dimensions = condition.dimensions;
    image_type = condition.type;
  }
  const { name } = target;
  // if (type.name === 'String' && !/[^0-9a-zA-Z]/.test(target.value)) return
  yield put(actions.handleInputChange(object));
  let state = yield select();
  let input_val = state.Mall.form[key][name].input_val
  let error = {};
  if (type.name === 'String') {
    error = yield stringValidation(name, input_val, required, min, max);
  } else if (type.name === 'Number') {
    error = yield numberValidation(name, input_val, required, min, max);
  } else if (type.name === 'Email') {
    error = yield emailValidation(name, input_val, required)
  } else if (type.name === 'File') {
    error = yield imageValidation(name, input_val, required, size, dimensions, image_type);
    const { base64 } = yield convertFileToBase64(input_val);
    yield put(actions.setMallLogo(base64))
  } else if (type.name === "Select") {
    error = yield selectValidation(name, input_val, required)
  }
  yield put(actions.handleMallErrorChange({error, name}));
}

function* handleAddMall ({data, history, companyId}) {
  console.log(data, history, companyId)
  try {
    yield put(actions.request())
    yield validateFinally(data);
    let object = yield mapAddCompanyKeyValue(data);
    yield mallService.addMall({...object, companyId: companyId});
    yield put(actions.successAddMall())
    history.push(`/companies/${companyId}/malls`);
  } catch(error) {
    console.log(error)
    yield put(actions.failedAddMall(error))
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetMallById ({id}) {
  try {
    yield put(actions.request());
    let response = yield mallService.getMallById(id);
    yield put(actions.setMallById(response.data.data));
  } catch (error) {
    console.log('COULD NOT FETCH COMPANY BY ID ', id);
  } finally {
    yield put(actions.complete())
  }
}

function* handleEditMall ({data}) {
  try {
    yield put(actions.request())
    yield validateFinally(data);
    let object = yield mapAddCompanyKeyValue(data);
    let state = yield select();
    let result = yield mallService.editMall(object, state.Mall.mall_id);
    yield put(actions.successEditMall({data: result.data.status.message}))
  } catch(error) {
    yield put(actions.failedAddMall(error))
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetMalls ({companyId, data}) {
  try {
    yield put(actions.request())
    const result = yield mallService.getMalls(companyId, data);
    yield put({type: mallActionTypes.SUCCESS_FETCH_MALLS, data: {malls: result.data.data.docs, total_malls: result.data.data.totalEntries}});
  } catch (error) {
    yield put({type: mallActionTypes.FAILED_FETCH_MALLS, data: error.response})
  } finally {
    yield put(actions.complete())
  }
}

export function* inputChange() {
  yield takeLatest(mallActionTypes.WATCH_MALL_INPUT_CHANGE, handleInputString)
}

export function* addMall() {
  yield takeEvery(mallActionTypes.WATCH_ADD_MALL, handleAddMall)
}

export function* getMallById() {
  yield takeLatest(mallActionTypes.WATCH_GET_MALL_BY_ID, handleGetMallById)
}

export function* editMall() {
  yield takeEvery(mallActionTypes.WATCH_EDIT_MALL, handleEditMall)
}

export function* getMalls() {
  yield takeLatest(mallActionTypes.WATCH_GET_MALLS, handleGetMalls)
}


export function* mallSaga() {
  yield all([
    call(inputChange),
    call(addMall),
    call(getMallById),
    call(editMall),
    call(getMalls),
  ]);
}
