import { companyActionTypes } from "./company.actiontype";
export const actions = {
  handleInputChange: (object) => ({
    type: companyActionTypes.HANDLE_INPUT_CHANGE,
    data: object,
  }),
  handleErrorChange: (data) => ({
    type: companyActionTypes.HANDLE_ERROR_CHANGE,
    data: data,
  }),
  request: () => ({ type: companyActionTypes.REQUEST }),
  complete: () => ({ type: companyActionTypes.COMPLETE }),
  successAddCompany: () => ({ type: companyActionTypes.SUCCESS_ADD_COMPANIES }),
  failedAddCompany: (error) => ({
    type: companyActionTypes.FAILED_ADD_COMPANY,
    data: error,
  }),
  setCompanyById: (data) => ({
    type: companyActionTypes.SET_BY_COMAPNY_ID,
    data,
  }),
  successEditCompany: ({ data }) => ({
    type: companyActionTypes.SUCCESS_EDIT_COMPANY,
    data,
  }),

  setCompanyLogo: (base64, file) => ({
    type: companyActionTypes.SET_COMPANY_LOGO,
    data: base64,
    file,
  }),
  setFavicon: (base64, file) => ({
    type: companyActionTypes.SET_FAVICON,
    data: base64,
    file,
  }),
  setSignupImage: (base64, file) => ({
    type: companyActionTypes.SET_SIGNUP_IMAGE,
    data: base64,
    file,
  }),
};
