import { takeLatest, put, all, call } from 'redux-saga/effects';
import { actions } from './controlCenter.action';
import { controlCenterActionTypes } from './controlCenter.actiontype';
import { controlCenterService } from '../../service';

function* handleGetControllMalls ({companyId}) {
  try {
    yield put(actions.request())
    const result = yield controlCenterService.getControlCenterMalls(companyId);
    yield put({type: controlCenterActionTypes.SUCCESS_FETCH_CONTROL_MALLS, data: {malls: result.data.data.docs}});
  } catch (error) {
    console.log('ERROR WHILE FETCHING CONTROL CENTER MALLS BY ID', error);
  } finally {
    yield put(actions.complete())
  }
}

function* handleAddMallFeatures ({data}) {
  try {
    yield put(actions.requestAddFeature());
    const result = yield controlCenterService.addMallFeatures(data);
    yield put(actions.successAddMallFeatures(data))
    console.log(result);
  } catch(error) {
    console.log('ADD MALL FEATURES - ', error);
  } finally {
    yield put(actions.completeAddFeature())
  }
} 

function* handleControlCenterChecks ({companyId}) {
  try {
    yield put(actions.request());
    const result = yield controlCenterService.getControlCenterChecks(companyId);
    yield put(actions.successControlCenterChecks(result.data.data));
    console.log(result);
  } catch(error) {
    console.log('ADD MALL FEATURES - ', error);
  } finally {
    yield put(actions.complete())
  }
}

export function* getControlCenterMalls() {
  yield takeLatest(controlCenterActionTypes.WATCH_CONTROL_CENTER_MALLS, handleGetControllMalls)
}

export function* addMallFeatures() {
  yield takeLatest(controlCenterActionTypes.WATCH_ADD_MALL_FEATURES, handleAddMallFeatures)
}

export function* getControlCenterChecks() {
  yield takeLatest(controlCenterActionTypes.WATCH_GET_CONTROL_CENTER_CHECK, handleControlCenterChecks)
}

export function* controlCenterSaga() {
  yield all([
    call(getControlCenterMalls),
    call(addMallFeatures),
    call(getControlCenterChecks)
  ]);
}
