export const initialState = {
  company_id: null,
  form: {
    company_information: {
      company_name: {
        input_val: "",
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 75,
        },
      },
      company_logo: {
        input_val: "",
        logoUrl: "",
        required: false,
        type: File,
        condition: {
          size: 2, // in mb
          dimensions: {
            height: 250,
            width: 250,
          },
          type: "png|jpeg|jpg",
        },
      },
      favicon: {
        input_val: "",
        faviconUrl: "",
        required: false,
        type: File,
        condition: {
          size: 2, // in mb
          dimensions: {
            height: 250,
            width: 250,
          },
          type: "png|jpeg|jpg|svg",
        },
      },
      signup_image: {
        input_val: "",
        signupUrl: "",
        required: false,
        type: File,
        condition: {
          size: 2, // in mb
          dimensions: {
            height: 250,
            width: 250,
          },
          type: "png|jpeg|jpg",
        },
      },
      access_key: {
        input_val: "",
        required: false,
        type: String,
        condition: {
          min: 32,
          max: 32,
        },
      },
      // company_GST: {
      //   input_val: '',
      //   required: true,
      //   type: String,
      //   condition: {
      //     min: 15,
      //     max: 15
      //   }
      // },
    },
    contact_information: {
      email: {
        input_val: "",
        required: true,
        type: {
          name: "Email",
        },
        condition: {
          min: 1,
          max: 250,
        },
      },
      country_code_primary: {
        input_val: "129",
        required: true,
        type: {
          name: "Select",
        },
        condition: {
          min: 4,
          max: 15,
        },
      },
      contact_number: {
        input_val: "",
        required: true,
        type: {
          name: "Number",
        },
        condition: {
          min: 4,
          max: 15,
        },
      },
      country_code_secondary: {
        input_val: "129",
        required: false,
        type: {
          name: "Select",
        },
        condition: {
          min: 4,
          max: 15,
        },
      },
      secondary_number: {
        input_val: "",
        required: false,
        type: {
          name: "Number",
        },
        condition: {
          min: 4,
          max: 15,
        },
      },
      // authorized_contact: {
      //   input_val: '',
      //   required: true,
      //   type: String,
      //   condition: {
      //     min: 1,
      //     max: 75
      //   }
      // }
    },
    // bank_details: {
    //   account_number: {
    //     input_val: '',
    //     required: true,
    //     type: Number,
    //     condition: {
    //       min: 3,
    //       max: 15
    //     }
    //   },
    //   bank_name: {
    //     input_val: '',
    //     required: true,
    //     type: String,
    //     condition: {
    //       min: 1,
    //       max: 100
    //     }
    //   },
    //   ifsc_code: {
    //     input_val: '',
    //     required: true,
    //     type: String,
    //     condition: {
    //       min: 11,
    //       max: 11
    //     }
    //   },
    //   branch_name: {
    //     input_val: '',
    //     required: true,
    //     type: String,
    //     condition: {
    //       min: 1,
    //       max: 100
    //     }
    //   }
    // },
    mailing_address: {
      address_line_1: {
        input_val: "",
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 100,
        },
      },
      address_line_2: {
        input_val: "",
        required: false,
        type: String,
        condition: {
          min: 1,
          max: 100,
        },
      },
      city: {
        input_val: "",
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 50,
        },
      },
      state: {
        input_val: "",
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 50,
        },
      },
      // country_name: {
      //   input_val: '',
      //   required: true,
      //   type: String,
      //   condition: {
      //     min: 1,
      //     max: 50
      //   }
      // },
      zip_code: {
        input_val: "",
        required: true,
        type: Number,
        condition: {
          min: 6,
          max: 6,
        },
      },
    },
    client_errors: [],
    server_errors: "",
    server_success: "",
  },
  loading: false,
  companies: [],
  total_companies: 0,
  error: "",
  companyAdmins: [],
  companyFeatures: [],
  resetCompanyAccountDetails: false,
};

export const serverToClientKeyValueMapping = {
  company_information: {
    instanceName: "company_name",
    instanceLogo: "company_logo",
    faviconUrl: "favicon",
    signUpImage: "signup_image",
    accessKey: "access_key",
    // companyGST: 'company_GST'
  },
  contact_information: {
    instanceEmail: "email",
    countryCodePrimary: "country_code_primary",
    instancePrimaryContact: "contact_number",
    countryCodeSecondary: "country_code_secondary",
    instanceSecondaryContact: "secondary_number",
    // companyAuthorizedContact: 'authorized_contact'
  },
  // bank_details: {
  //   accountNumber: 'account_number',
  //   bankName: 'bank_name',
  //   ifscCode: 'ifsc_code',
  //   branchName: 'branch_name',
  // },
  mailing_address: {
    instanceAddress1: "address_line_1",
    instanceAddress2: "address_line_2",
    instanceCity: "city",
    instanceState: "state",
    // companyCountry: 'country_name',
    instancePincode: "zip_code",
  },
};
