import React from "react";
import PinIcon from "../assets/ic-map-pin.svg";
import PhoneIcon from "../assets/ic-phone.svg";
import InstagramIcon from "../assets/ic-instagram.svg";
import FacebookIcon from "../assets/ic-facebook.svg";
import TwitterIcon from "../assets/ic-twitter.svg";
import CopyIcon from "../assets/ic-copyright.svg";
import MenuHome from "../assets/MenuHome";
import MenuFeedIcon from "../assets/menu-feed.svg";
import MenuScanIcon from "../assets/menu-scan.svg";
import MenuOffersIcon from "../assets/menu-offers.svg";
import MenuLoyaltyIcon from "../assets/menu-loyalty.svg";
import MobileScanEarn from "../assets/MobileScanEarn";

const exploreLinks = [
  {
    id: 1,
    title: "Home",
    icon: "",
  },
  {
    id: 2,
    title: "Feed",
    icon: MenuFeedIcon,
  },
  {
    id: 3,
    title: "Scan & Earn",
    icon: MenuScanIcon,
  },
  {
    id: 4,
    title: "Offers",
    icon: MenuOffersIcon,
  },
  {
    id: 5,
    title: "Loyalty Hub",
    icon: MenuLoyaltyIcon,
  },
];

const moreLinks = [
  {
    id: 1,
    title: "About Us",
  },
  {
    id: 2,
    title: "FAQs",
  },
  {
    id: 3,
    title: "Help",
  },
  {
    id: 4,
    title: "Terms of Service",
  },
  {
    id: 5,
    title: "Privacy Policy",
  },
];

const socialLinks = [
  {
    id: 1,
    title: "Instagram",
    icon: InstagramIcon,
  },
  {
    id: 2,
    title: "Facebook",
    icon: FacebookIcon,
  },
  {
    id: 3,
    title: "Twitter",
    icon: TwitterIcon,
  },
];

const Footer = ({ formData, isPreviewDesktop }) => {
  return isPreviewDesktop ? (
    <div className="footer-ctn">
      <div className="footer-link-ctn">
        <div className="logo-ctn">
          <img
            src={
              isPreviewDesktop
                ? formData.desktopCompanyLogo.preview
                : formData.mobileCompanyLogo.preview
            }
            alt="company logo"
            className="logo-img"
          />
        </div>
        <div className="link-ctn">
          {/* address */}
          <div className="address-contact-ctn">
            <div className="address-ctn">
              <div className="pin-img-ctn">
                <img src={PinIcon} alt="map" className="pin-img" />
              </div>
              <div className="address-text">
                S No. 207, Viman Nagar Road, Viman, Nagar, Pune - 411014
              </div>
            </div>
            <div className="contact-ctn">
              <div className="phone-img-ctn">
                <img src={PhoneIcon} alt="contact" className="phone-img" />
              </div>
              <div className="phone-text">91+ 9999999999</div>
            </div>
          </div>
          <div className="side-link-ctn">
            {/* Explore links */}
            <div className="explore-ctn">
              <div className="link-title">Explore</div>
              <div className="all-links">
                {exploreLinks.map((item) => (
                  <div className="link" key={item.id}>
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            {/* More links */}
            <div className="more-ctn">
              <div className="link-title">More</div>
              <div className="all-links">
                {moreLinks.map((item) => (
                  <div className="link" key={item.id}>
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            {/* Follow */}
            <div className="follow-ctn">
              <div className="link-title">Follow</div>
              <div className="follow-links-ctn">
                {socialLinks.map((item) => (
                  <div key={item.id} className="social-img-ctn">
                    <img
                      src={item.icon}
                      alt={item.title}
                      className="social-img"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="copyright-ctn">
        <div className="copy-img-ctn">
          <img src={CopyIcon} alt="copyright" className="copy-img" />
        </div>
        <div className="copyright-text">
          <span>{new Date().getFullYear()}</span>
          <span>Ariston.</span>
          <span>All Rights Reserved.</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="mobile-footer-ctn">
      <div className="mobile-footer-menu">
        {/* {exploreLinks.map((item, i) => (
          <div className="menu-link">
            {i === 0 ? (
              <MenuHome color={formData.colour} className="menu-home-icon" />
            ) : (
              <img src={item.icon} alt="menu icon" className="menu-icon" />
            )}
            <span className="menu-text">{item.title}</span>
          </div>
        ))} */}
        {/* Left nav */}
        <div className="normal-nav">
          <div className="menu-link">
            <MenuHome color={formData.colour} className="menu-home-icon" />
            <span className="menu-text" style={{ color: formData.colour }}>
              Home
            </span>
          </div>
          <div className="menu-link">
            <img src={MenuFeedIcon} alt="menu-icon" className="menu-icon" />
            <span className="menu-text">Feed</span>
          </div>
        </div>
        {/* Scan */}
        <div className="cta-nav">
          <div className="scan-img-wrapper">
            <div
              className="scan-img-ctn"
              style={{ border: `2px solid ${formData.colour}` }}
            >
              <MobileScanEarn color={formData.colour} />
            </div>
          </div>
          <span className="menu-text">Scan & Earn</span>
        </div>
        {/* Right nav */}
        <div className="normal-nav">
          <div className="menu-link">
            <img src={MenuLoyaltyIcon} alt="menu-icon" className="menu-icon" />
            <span className="menu-text">Loyalty Hub</span>
          </div>
          <div className="menu-link">
            <img src={MenuOffersIcon} alt="menu-icon" className="menu-icon" />
            <span className="menu-text">Offers</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
