import {controlCenterActionTypes} from './controlCenter.actiontype';
let initial_state = {
  malls: [],
  loading: false,
  add_feature_loading: false,
  control_center_checks: {
    companyFeatures: null,
    malls: null,
    pointDetails: null,
    userDetails: null
  }
}

export const controlCenter  = (state = initial_state, action = {}) => {
  switch(action.type){

    case controlCenterActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case controlCenterActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case controlCenterActionTypes.SUCCESS_FETCH_CONTROL_MALLS:
  
      return {
        ...state,
        malls: action.data.malls
      };

    case controlCenterActionTypes.REQUEST_ADD_FEATURE:
      return {
        ...state,
        add_feature_loading: true
      }

    case controlCenterActionTypes.COMPLETE_ADD_FEATURE:
      return {
        ...state,
        add_feature_loading: false
      }
  
    case controlCenterActionTypes.SUCCESS_ADD_MALL_FEATURES:
      return {
        ...state,
        malls: state.malls.map(mall => {
          if (mall.mallId === action.data.mallId) {
            let temp_arr = []
            for (let m of mall.moduleData) {
              for (let n of action.data.activateModule) {
                if (m.id === n.id) {
                  m.moduleStatus = n.moduleStatus
                }
              }
              temp_arr.push(m)
            } 
            return {
              ...mall,
              moduleData: temp_arr
            }
          } else {
            return mall;
          }
        })
      };

    case controlCenterActionTypes.SUCCESS_CONTROL_CENTER_CHECKS:
      return {
        ...state,
        control_center_checks: action.data
      };


    default:
      return state;
  }
}