import React from "react";
import AddEditImage from "../components/AddEditImage";

const ImageInfo = () => {
  return (
    <ul>
      <li>Aspect ratio: 1:1</li>
      <li>Max image upload size: 5MB</li>
      <li>Format: JPEG, PNG</li>
    </ul>
  );
};

const FaviconField = ({ formData, setFormData }) => {
  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      let aspectRatio;
      let error = "";

      const previewURL = URL.createObjectURL(file);

      img.onload = () => {
        aspectRatio = img.width / img.height;
        if (file.size > 5 * 1024 * 1024) {
          error = "File size exceeds the maximum limit.";
        } else if (aspectRatio !== 1) {
          error = "File doesn’t match specified aspect ratio.";
        }
        setFormData((prev) => ({
          ...prev,
          favicon: { name: file.name, preview: previewURL, file, error },
        }));
      };
    }
  };

  return (
    <div className="tab-content">
      <AddEditImage
        data={formData.favicon}
        handleUploadImage={handleUploadImage}
        id="favicon"
        imageInfo={ImageInfo}
      />
    </div>
  );
};

export default FaviconField;
