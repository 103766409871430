import React, { useState } from "react";
import AddEditImage from "../components/AddEditImage";

const ImageInfo = () => {
  return (
    <ul>
      <li>Max image upload size: 5MB</li>
      <li>Format: JPEG, PNG</li>
    </ul>
  );
};

const CompanyLogoField = ({ formData, setFormData }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const handleUploadImage = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      let aspectRatio;
      let error = "";

      const previewURL = URL.createObjectURL(file);

      img.onload = () => {
        aspectRatio = img.width / img.height;
        if (file.size > 5 * 1024 * 1024) {
          error = "File size exceeds the maximum limit.";
        }
        // else if (aspectRatio !== 1) {
        //   error = "File doesn’t match specified aspect ratio.";
        // }
        if (type === "desktop") {
          setFormData((prev) => ({
            ...prev,
            desktopCompanyLogo: {
              name: file.name,
              preview: previewURL,
              file,
              error,
            },
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            companyLogoMobile: file,
            mobileCompanyLogo: {
              name: file.name,
              preview: previewURL,
              file,
              error,
            },
          }));
        }
      };
    }
  };
  return (
    <div>
      <div className="tab-title-ctn">
        <div
          className={`tab-title ${activeTab === 1 && "active"}`}
          onClick={() => handleTabChange(1)}
        >
          MOBILE
        </div>
        <div
          className={`tab-title ${activeTab === 2 && "active"}`}
          onClick={() => handleTabChange(2)}
        >
          DESKTOP
        </div>
        <div className="bottom-border"></div>
      </div>
      <div className="tab-content">
        {activeTab === 1 ? (
          <AddEditImage
            data={formData.mobileCompanyLogo}
            type={"mobile"}
            handleUploadImage={handleUploadImage}
            id="mobile-company-logo"
            imageInfo={ImageInfo}
          />
        ) : (
          <AddEditImage
            data={formData.desktopCompanyLogo}
            type={"desktop"}
            handleUploadImage={handleUploadImage}
            id="desktop-company-logo"
            imageInfo={ImageInfo}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyLogoField;
