import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AsideMenuList from './asideMenuList';
import MallMenuList from './mallMenu';

const MenuList = (props) => {
  const {layoutProps, getMenuItemActive} = props;

  return (
    <>
      <Switch>
        {/* <Route
          exact
          path="/companies/add-company"
          render = {(inProps) => <AsideMenuList {...inProps} {...props}/>}
        />
        <Route
          path="/companies/:companyId"
          render = {(inProps) => <MallMenuList {...inProps} {...props}/>}
        /> */}
        <Route
          path="*"
          render = {(inProps) => <AsideMenuList {...inProps} {...props}/>}
        />
      </Switch>
    </>
  )
}

export default MenuList;
