export const mmActiontypes = {
  MODULES_INIT: 'MODULES_INIT',
  MODULES_SUCCESS: 'MODULES_SUCCESS',
  MM_INITALDATA: 'MM_INITALDATA',
  MALL_SWITCH_TOGGLE: 'MALL_SWITCH_TOGGLE',
  MALL_SWITCH_INIT: 'MALL_SWITCH_INIT',  
  MALL_SWITCH_SUCCESS: 'MALL_SWITCH_SUCCESS',
  MM_ERROR: 'MM_ERROR',
  MM_ERROR_CLEAR: 'MM_ERROR_CLEAR',
  MM_CRUD_STATUS: 'MM_CRUD_STATUS',
  LOGOUT: 'LOGOUT',
  SET_MALL_ID: 'SET_MALL_ID',
  MM_SET_MALLS: 'MM_SET_MALLS'
}