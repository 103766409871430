import React from "react";
import AddIcon from "../assets/add-image.svg";
import EditIcon from "../assets/edit.svg";
import ErrorIcon from "../assets/error.svg";

const AddEditImage = ({
  data,
  type,
  handleUploadImage,
  id,
  imageInfo: ImageInfo,
}) => {
  return (
    <div className="company-logo-ctn">
      <div className="company-logo-input">
        <div className="preview-ctn">
          {data.preview ? (
            <div className="preview">
              <img
                src={data.preview}
                alt="company logo preview"
                className="preview-img"
              />
              <div className="edit-image">
                <label htmlFor={id}>
                  <img src={EditIcon} alt="edit" />
                </label>
                <input
                  type="file"
                  accept="image/jpeg,image/jpg,image/png"
                  style={{ display: "none" }}
                  id={id}
                  onChange={(e) => handleUploadImage(e, type)}
                  onClick={(e) => (e.target.value = null)}
                  // disabled={checkDisabled("qrLogo")}
                />
              </div>
            </div>
          ) : (
            <label htmlFor={id} className="company-logo">
              <div className="add-img">
                <img src={AddIcon} alt="add company logo" />
              </div>
            </label>
          )}
          <input
            type="file"
            accept="image/jpeg,image/jpg,image/png"
            style={{ display: "none" }}
            id={id}
            onChange={(e) => handleUploadImage(e, type)}
            onClick={(e) => (e.target.value = null)}
            // disabled={checkDisabled("qrLogo")}
          />
        </div>
        <div className="company-logo-info">
          <ImageInfo />
        </div>
      </div>
      {!!data.error && (
        <div className={`image-error`}>
          <img src={ErrorIcon} alt="error" />
          {data.error}
        </div>
      )}
    </div>
  );
};

export default AddEditImage;
