import {HTTPClient} from './http.service';

export const mallService = {
  async addMall(data) {
    try {
      let result = await HTTPClient.post('/mall', data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getMallById (id) {
    try {
      let result = await HTTPClient.get(`/mall/${id}`)
      return result
    } catch (error) {
      throw error;
    }
  },
  async editMall(data, id) {
    try {
      let result = await HTTPClient.put(`/mall/${id}`, data);
      return result;
    } catch (error) {
      let error_object = { error_type: 'SERVER' };
      if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
      else error_object.errors = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getMalls (companyId, data) {
    try {
      let result = await HTTPClient.get(`/mall/byCompany/${companyId}/`, data)
      return result
    } catch (error) {
      throw error;
    }
  }
}
