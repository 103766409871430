import {
  takeLatest,
  takeEvery,
  put,
  select,
  all,
  call,
} from "redux-saga/effects";
import {
  stringValidation,
  numberValidation,
  emailValidation,
  imageValidation,
  selectValidation,
} from "./../../utils/inputValidations";
import { validateFinally } from "./../../utils/inputValidations";
import { actions } from "./company.action";
import { companyActionTypes } from "./company.actiontype";
import { companyService } from "../../service";
import { convertFileToBase64 } from "./../../utils/common";
import { serverToClientKeyValueMapping } from "./company.initial_state";

function* mapAddCompanyKeyValue(data) {
  const { company_information } = data;
  let formData = new FormData();
  for (const [key, value] of Object.entries(serverToClientKeyValueMapping)) {
    for (const [key1, value1] of Object.entries(value)) {
      if (key1 !== "companyLogo") {
        if (data?.[key]?.[value1]) {
          if (
            data[key][value1].type.name === "Number" ||
            data[key][value1].type.name === "Select"
          ) {
            formData.append(
              `${key1}`,
              data[key][value1].input_val
                ? Number(data[key][value1].input_val)
                : null
            );
          } else {
            formData.append(`${key1}`, data[key][value1].input_val);
          }
        }
      }
    }
  }

  return formData;
}

function* handleInputString({ object }) {
  const { target, initialState, key } = object;
  const { required, condition, type } = initialState;
  let min, max, size, dimensions, image_type;
  if (type.name !== "File") {
    min = condition.min;
    max = condition.max;
  } else {
    size = condition.size;
    dimensions = condition.dimensions;
    image_type = condition.type;
  }
  const { name } = target;
  yield put(actions.handleInputChange(object));
  let state = yield select();
  let input_val = state.Company.form[key][name].input_val;
  let error = {};
  if (type.name === "String") {
    error = yield stringValidation(name, input_val, required, min, max);
  } else if (type.name === "Number") {
    error = yield numberValidation(name, input_val, required, min, max);
  } else if (type.name === "Email") {
    error = yield emailValidation(name, input_val, required);
  } else if (type.name === "File") {
    error = yield imageValidation(
      name,
      input_val,
      required,
      size,
      dimensions,
      image_type
    );
    const { base64 } = yield convertFileToBase64(input_val);
    if (name === "company_logo") {
      yield put(actions.setCompanyLogo(base64, input_val));
    } else if (name === "favicon") {
      yield put(actions.setFavicon(base64, input_val));
    } else {
      yield put(actions.setSignupImage(base64, input_val));
    }
  } else if (type.name === "Select") {
    error = yield selectValidation(name, input_val, required);
  }
  yield put(actions.handleErrorChange({ error, name }));
}

function* handleAddCompany({ data, history }) {
  try {
    yield put(actions.request());
    yield validateFinally(data);
    let object = yield mapAddCompanyKeyValue(data);
    yield companyService.addCompany(object);
    yield put(actions.successAddCompany());
    history.push("/companies");
  } catch (error) {
    console.log(error);
    yield put(actions.failedAddCompany(error));
  } finally {
    yield put(actions.complete());
  }
}

function* handleGetCompanyById({ id }) {
  try {
    yield put(actions.request());
    let response = yield companyService.getCompanyById(id);
    yield put(actions.setCompanyById(response.data.data));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.complete());
  }
}

function* handleEditCompany({ companyId, data }) {
  try {
    yield put(actions.request());
    yield validateFinally(data);
    let object = yield mapAddCompanyKeyValue(data);
    let state = yield select();
    let result = yield companyService.editCompany(object, companyId);
    yield put(actions.successEditCompany({ data: result.data.status.message }));
  } catch (error) {
    console.log(error);
    yield put(actions.failedAddCompany(error));
  } finally {
    yield put(actions.complete());
  }
}

function* handleGetCompanies({ data }) {
  try {
    yield put(actions.request());
    const result = yield companyService.getCompanies(data);
    yield put({
      type: companyActionTypes.SUCCESS_FETCH_COMPANIES,
      data: {
        companies: result.data.data.docs,
        total_companies: result.data.data.totalEntries,
      },
    });
  } catch (error) {
    yield put({
      type: companyActionTypes.FAILED_FETCH_COMPANIES,
      data: error.response,
    });
  } finally {
    yield put(actions.complete());
  }
}

export function* inputChange() {
  yield takeLatest(companyActionTypes.WATCH_INPUT_CHANGE, handleInputString);
}

export function* addCompany() {
  yield takeEvery(companyActionTypes.WATCH_ADD_COMPANY, handleAddCompany);
}

export function* getCompanyById() {
  yield takeLatest(
    companyActionTypes.WATCH_GET_COMPANY_BY_ID,
    handleGetCompanyById
  );
}

export function* editCompany() {
  yield takeEvery(companyActionTypes.WATCH_EDIT_COMPANY, handleEditCompany);
}

export function* getCompanies() {
  yield takeLatest(companyActionTypes.WATCH_GET_COMPANIES, handleGetCompanies);
}

export function* companySaga() {
  yield all([
    call(inputChange),
    call(addCompany),
    call(getCompanyById),
    call(editCompany),
    call(getCompanies),
  ]);
}

// *********************** DUMMY

// function* mapAddCompanyKeyValue (data) {
//   const { company_information, contact_information, bank_details, mailing_address } = data;
//   const { base64 } = yield convertFileToBase64(company_information.company_logo.input_val);
//   let object = {
//     // company_information
//     companyName: company_information.company_name.input_val,
//     companyGST: company_information.company_GST.input_val,
//     // contact_information
//     companyEmail: contact_information.email.input_val,
//     companyPrimaryContact: contact_information.contact_number.input_val,
//     companySecondaryContact: contact_information.secondary_number.input_val,
//     companyAuthorizedContact: contact_information.authorized_contact.input_val,
//     // bank_details
//     bankName: bank_details.bank_name.input_val,
//     accountNumber: bank_details.account_number.input_val,
//     ifscCode: bank_details.ifsc_code.input_val,
//     branchName: bank_details.branch_name.input_val,
//     // mailing_address
//     companyAddress1: mailing_address.address_line_1.input_val,
//     companyAddress2: mailing_address.address_line_2.input_val,
//     companyCity: mailing_address.city.input_val,
//     companyState: mailing_address.state.input_val,
//     companyCountry: mailing_address.country_name.input_val,
//     companyPincode: mailing_address.zip_code.input_val,
//   }
//   let company_object = {};
//   for (const [key, value] of Object.entries(serverToClientKeyValueMapping)) {
//     for (const [key1, value1] of Object.entries(value)) {
//       if (key1 !== 'companyLogo') company_object[key1] = data[key][value1].input_val;
//     }
//   }

//   console.log('11111', object);
//   console.log('22222', company_object);
//   if (base64) {
//     object.companyLogo = base64;
//     company_object.companyLogo = base64;
//   }
//   return company_object;
// }
