import axios from "axios";
import { HTTPClient } from "..";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const brandingService = {
  async getGoogleFonts() {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getBranding() {
    try {
      const response = await HTTPClient.get(`/branding`);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async saveBranding(data) {
    try {
      const response = await HTTPClient.customRequest({
        url: `/branding/updateBranding`,
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: data,
      });
      // HTTPClient.put(`branding/updateBranding`, data);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
