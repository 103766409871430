import {HTTPClient} from './http.service';
import {stringify} from 'qs'

export const teamManagement = {
  async getUsersList(params) {
    try {
      const {data:{data: usersDocs}} = await  HTTPClient.get('/users/', params);
      return usersDocs;
    }catch(e){
      return Promise.reject(e.response);
    }
  },
  async getUserDetails(id) {
    try{
      const {data:{data:userDetails}} = await HTTPClient.get(`/users/${id}`)
      return userDetails
    }catch(e) {
      return Promise.reject(e.response);
    }
  },
  async deleteUser(id) {
    try{
      const response = await HTTPClient.delete(`/users/${id}`)
      return response
    }catch(e) {
      return Promise.reject(e.response);
    }
  },
  async getRolesList(params) {
    try{
      const {data:{data:rolesList}} = await HTTPClient.get('/users/roles', params)
      return rolesList
    }catch(e) {
      return Promise.reject(e.response);
    }
  },
  async addUser(data) {
    try{
      const {data:{data:rolesList}} = await HTTPClient.post('/users', data);
      return rolesList
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async addCenterDirector (dataObj, zone) {
    try{
      const {data:{data}} = await HTTPClient.post('/settings/addUser/' + zone, dataObj);
      return data;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },

  async addHandlerUser(data) {
    try{
      const {data:{data:rolesList}} = await HTTPClient.post('/users/addHandlerUser', data);
      return rolesList
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async updateUserRole(id, data) {
    try{
      const {data:{data:rolesList}} = await HTTPClient.customRequest({
        url: `/users/roles/updateUserRole/${id}`,
        method: 'put',
        data: data
      });
      return rolesList
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },

  async getallmodules () {
    try {
      const {data:{data: allModules}} = await HTTPClient.get('/users/getModules');
      return allModules;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getRoleModules(id) {
    try {
      const {data:{data: allModules}} = await HTTPClient.get(`/users/roles/singleRole/${id}`);
      return allModules;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async addNewRole(data) {
    try {
      const {data:{data: allModules}} = await HTTPClient.customRequest({
        url: `/users/roles`,
        method: 'post',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: stringify(data)
      });
      return allModules;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async updateRole(data, id) {
    try {
      const response = await HTTPClient.customRequest({
        url: `/users/roles/${id}`,
        method: 'put',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: stringify(data)
      });
      return response;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },

  async deleteRole(id) {
    try {
      const response = await HTTPClient.delete(`/users/roles/${id}`);
      return response
    } catch(e) {
      return Promise.reject(e);
    }
  },

  async resendVerification(data) {
    try {
      const response = await HTTPClient.post('/users/resendVerification', data);
      return response;
    }catch(e) {
      return Promise.reject(e);
    }
  }

}
