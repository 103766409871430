import React from "react";
import ResetIcon from "../assets/reset.svg";

const FormWrapper = ({
  children,
  title,
  isRequired,
  isReset,
  setIsBannerReset,
}) => {
  return (
    <div className="form-wrapper">
      <div className="form-title">
        <div>
          {title} {isRequired && <span className="requiredField">*</span>}
        </div>
        {isReset && (
          <img
            src={ResetIcon}
            alt="reset"
            className="reset"
            onClick={() => setIsBannerReset(true)}
          />
        )}
      </div>
      <div className="children-ctn">{children}</div>
    </div>
  );
};

export default FormWrapper;
