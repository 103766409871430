/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {useSelector} from 'react-redux'
import { Link } from "react-router-dom";

export function SubHeader() {

  let aside_menu = document.getElementById('kt_aside_menu');
  let header_menu = document.getElementById('kt_header_menu');

  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const breadcrumTitle = useSelector(state => state.appData.breadcrumTitle)
  const pathname = (typeof window !== 'undefined' ? window.location.pathname : '');

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    if( aside_menu || header_menu){
      const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
      const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
      let breadcrumbs = [];
      if (aside && aside.breadcrumbs && aside.breadcrumbs.length > 0) {
        breadcrumbs = aside.breadcrumbs;
      } else {
        breadcrumbs = header.breadcrumbs;
      }
      subheader.setBreadcrumbs(breadcrumbs);
      subheader.setTitle(aside.title);
    }
    // eslint-disable-next-line
  }, [location.pathname, aside_menu, header_menu]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  
  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex align-items-baseline mr-5">
            {/* begin::Title */}
            <h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">
              {subheader.title}
            </h2>
            {/* end::Title */}
            {/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
            {breadcrumTitle && <h3 class=" text-dark font-weight-bold my-2 mr-3">{breadcrumTitle}</h3>}
            {
              pathname === '/footfall-report'
              ?
              <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
                <Link className="breadcrumb-item" to="/overview/overall">
                  Dashboard
                </Link>
                  <li className="breadcrumb-item">
                    <Link
                      className="text-muted "
                      to={{ pathname: pathname }}
                    >
                      Footfall Report
                    </Link>
                  </li>
              </ul>
              :
              <BreadCrumbs items={subheader.breadcrumbs} />
            }
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        {/* <div className="d-flex align-items-center">
          <button
            type="button"
            className={`btn btn-fixed-height ${
              layoutProps.subheaderFixed ? "btn-default" : "btn-white"
            } btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2`}
          >
            <span className="svg-icon svg-icon-success svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            {` `}New Member
          </button>

          <QuickActions />


          <button
            className="btn btn-success btn-icon font-weight-bold"
            data-toggle="modal"
            data-target="#kt_chat_modal"
          >
            <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
              />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
}
