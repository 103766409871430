export default {
  self: {},
  items: [
    {
      title: "Branding",
      key: "branding",
      root: true,
      icon: "fas fa-magic",
      bullet: "dot",
      page: "branding",
      submenu: [],
    },
    {
      title: "Communication",
      key: "communication",
      root: true,
      icon: "flaticon2-bell-5",
      bullet: "dot",
      page: "deep-link-management",
      submenu: [
        {
          title: "Deep Link Management",
          key: "deepLink",
          page: "deep-link-management",
          bullet: "dot",
        },
        // {
        //   title: "Notification center",
        //   key: "notificationCenter",
        //   bullet: "dot",
        //   page: "notifications",
        // },
        {
          title: "Referral Code Manager",
          key: "referralCampaign",
          bullet: "dot",
          page: "referral",
        },
        {
          title: "Referral Revamp",
          key: "referralCampaignV2",
          bullet: "dot",
          page: "revamp",
        },
        {
          title: "Survey",
          key: "surveyManager",
          page: "survey",
          bullet: "referral1",
        },
      ],
    },
    {
      title: "Content",
      key: "content",
      root: true,
      icon: "flaticon2-sheet",
      bullet: "dot",
      submenu: [
        {
          title: "Banners",
          key: "banner",
          bullet: "dot",
          page: "banner",
        },
        {
          title: "Onboarding Screens",
          key: "onboardingScreen",
          bullet: "dot",
          page: "onboarding-screens",
        },
        {
          title: "Feed Manager",
          key: "mallFeed",
          bullet: "dot",
          page: "feed-managers",
        },
      ],
    },
    {
      title: "Customers",
      key: "customers",
      root: true,
      icon: "flaticon-truck",
      bullet: "dot",
      page: "customer-management",
      submenu: [],
    },
    {
      title: "Loyalty",
      key: "loyalty",
      root: true,
      icon: "flaticon2-layers-2",
      bullet: "dot",
      page: "coupon-management",
      submenu: [
        {
          title: "Coupon Management",
          key: "couponManagement",
          icon: "flaticon-price-tag",
          page: "coupon-management",
          bullet: "dot",
        },
        {
          title: "Coupon Management Pro",
          key: "couponManagementPro",
          icon: "flaticon-price-tag",
          page: "coupon-management-full",
          bullet: "dot",
        },
        {
          title: "QR Code Generation",
          key: "qrCampaign",
          icon: "flaticon-price-tag",
          page: "qr-code-generation",
          bullet: "dot",
        },
        {
          title: "UPI Cashback",
          key: "cashbackPayouts",
          icon: "flaticon-price-tag",
          page: "upi-cashback",
          bullet: "dot",
        },
        {
          title: "Quiz",
          key: "quiz",
          icon: "flaticon-price-tag",
          page: "quiz",
          bullet: "dot",
        },
        {
          title: "Scan and Earn Management",
          key: "refuteManagement",
          icon: "flaticon-price-tag",
          page: "refute-management",
          bullet: "dot",
        },
        {
          title: "Scan and Earn Management",
          key: "scanAndEarnAriston",
          icon: "flaticon-price-tag",
          page: "scan-and-earn-management",
          bullet: "dot",
        },
        {
          title: "Rule Engine",
          key: "rule",
          root: true,
          icon: "flaticon-interface-1",
          page: "rule-engine",
          bullet: "dot",
        },
        {
          title: "Reward Management",
          key: "rewardManagement",
          root: true,
          icon: "flaticon-price-tag",
          page: "reward-management",
          bullet: "dot",
        },
        {
          title: "Delight Transfer",
          key: "customerDelight",
          root: true,
          icon: "flaticon-price-tag",
          page: "customer-delight",
          bullet: "dot",
        },
        {
          title: "Shop and Win",
          key: "scanCampaign",
          root: true,
          icon: "flaticon-price-tag",
          page: "shop-and-win",
          bullet: "dot",
        },
        {
          title: "Spin the Wheel",
          key: "spinWheel",
          root: true,
          icon: "flaticon-price-tag",
          page: "spin-the-wheel",
          bullet: "dot",
        },
        {
          title: "Scratch Card",
          key: "scratchCard",
          icon: "flaticon-price-tag",
          page: "scratch-card-management",
          bullet: "dot",
        },
        {
          title: "Personalized Visits",
          key: "personalizedInstanceVisit",
          root: true,
          icon: "flaticon2-dashboard",
          page: "personalised-plan-visit",
          bullet: "dot",
          submenu: [],
        },
      ],
    },
    {
      title: "Retailers",
      key: "retailers",
      root: true,
      icon: "flaticon2-tag",
      bullet: "dot",
      page: "retailers",
      submenu: [],
    },
    {
      title: "Plan Visit Booking",
      key: "bookingManagement",
      root: true,
      icon: "flaticon2-dashboard",
      page: "booking-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Parking Management",
      key: "parking",
      root: true,
      icon: "fas fa-parking",
      page: "parking",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Baggage Services",
      key: "baggage",
      root: true,
      icon: "fas fa-shopping-bag",
      page: "baggage-services",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Dineout Orders",
      key: "dineout",
      root: true,
      icon: "fas fa-utensils",
      page: "dineout",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Team Management",
      key: "userManagement",
      root: true,
      icon: "flaticon2-user-1",
      page: "user-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Reports",
      key: "reports",
      root: true,
      icon: "fas fa-newspaper",
      bullet: "dot",
      page: "coupon-redemption",
      submenu: [
        {
          title: "Coupon Redemption Report",
          key: "couponRedemptionReport",
          bullet: "dot",
          page: "coupon-redemption",
        },
        {
          title: "Reward Redemption Report",
          key: "rewardRedemptionReport",
          bullet: "dot",
          page: "reward-redemption",
        },
        {
          title: "Scan Report",
          key: "customerScansReport",
          bullet: "dot",
          page: "scan-reports",
        },
        {
          title: "Contact Less Payment Log Report",
          key: "paymentTransaction",
          bullet: "dot",
          page: "contactless-payment-log-report",
        },
        {
          title: "Favourite Stores Report List",
          key: "favouriteStoreReport",
          bullet: "dot",
          page: "fav-store-report-list",
        },
        {
          title: "Report List",
          key: "reportsManagement",
          bullet: "dot",
          page: "report-list",
        },
        {
          title: "Customers by DOB",
          key: "customerByDob",
          bullet: "dot",
          page: "customers-by-dob-list",
        },
        {
          title: "Customers by Anniversary",
          key: "customerByAnniversary",
          bullet: "dot",
          page: "customers-by-anniversary-list",
        },
      ],
    },
    {
      title: "Issue Management",
      key: "issueManagement",
      root: true,
      icon: "fas fa-tasks",
      page: "issue-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Handler Apps",
      key: "handlerApp",
      root: true,
      icon: "fas fa-tablet",
      page: "handler",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Concierge Services",
      key: "concierge",
      root: true,
      icon: "flaticon-notepad",
      bullet: "dot",
      page: "scan-service",
      submenu: [
        {
          title: "Scan Service",
          key: "conciergeScan",
          bullet: "dot",
          page: "scan-service",
        },
        {
          title: "Physical Coupon Distribution",
          key: "conciergePhysicalVoucher",
          bullet: "dot",
          page: "concierge-physical-distribution-coupon-vouchers",
        },
        {
          title: "Physical Reward Distribution",
          key: "conciergePhysicalVoucher",
          bullet: "dot",
          page: "concierge-physical-distribution-reward-vouchers",
        },
        {
          title: "Other Issues",
          key: "conciergeIssue",
          bullet: "dot",
          page: "other-issues",
        },
      ],
    },
    {
      title: "Voucher Management",
      key: "voucherManagement",
      root: true,
      icon: "flaticon2-bell-5",
      page: "voucher/backend/inwards",
      bullet: "dot",
      submenu: [
        {
          title: "Voucher Inward",
          key: "inwardInventory",
          page: "voucher/backend/inwards",
          bullet: "dot",
        },
        {
          title: "Voucher Outward",
          key: "outwardInventory",
          bullet: "dot",
          page: "voucher/backend/outwards",
        },
        {
          title: "Retailers",
          key: "retailerInventory",
          bullet: "dot",
          page: "voucher/backend/voucher-retailer",
        },
      ],
    },
    {
      title: "Frontdesk Management",
      key: "voucherManagement",
      root: true,
      icon: "flaticon2-bell-5",
      bullet: "dot",
      page: "voucher/frontdesk/inwards",
      submenu: [
        {
          title: "Voucher Inward",
          key: "inwardInventory",
          page: "voucher/frontdesk/inwards",
          bullet: "dot",
        },
        {
          title: "Voucher Outward",
          key: "outwardInventory",
          bullet: "dot",
          page: "voucher/frontdesk/outwards",
        },
      ],
    },
    {
      title: "Static Pages",
      key: "staticPage",
      root: true,
      icon: "flaticon-file-1",
      bullet: "dot",
      page: "static-pages",
      submenu: [],
    },
    {
      title: "Earn Form",
      key: "earnForm",
      root: true,
      icon: "fas fa-tasks",
      bullet: "dot",
      page: "driver-details",
      submenu: [],
    },
    {
      title: "Controller Apps",
      key: "controllersApp",
      root: true,
      icon: "flaticon2-expand",
      bullet: "dot",
      page: "controllers",
      submenu: [],
    },
    {
      title: "Add Super User",
      key: "companies",
      root: true,
      icon: "flaticon2-group",
      bullet: "dot",
      page: "super-user",
      submenu: [],
      admin: true,
    },
    {
      title: "Companies",
      key: "companies",
      root: true,
      icon: "flaticon2-expand",
      bullet: "dot",
      page: "companies",
      submenu: [],
      admin: true,
    },
  ],
};
