export const PageName = {
  NOTIFICATIONCENTER: {
    title: "Notification Center",
    moduleKey: "notificationCenter",
  },
  USERMANAGEMENT: { title: "User Management", moduleKey: "userManagement" },
  COUPONLITE: {
    title: "Coupon Management Lite",
    moduleKey: "couponManagement",
  },
  COUPONPRO: {
    title: "Coupon Management Pro",
    moduleKey: "couponManagementPro",
  },
  QRCODEGENERATION: {
    title: "QR Code Generation",
    moduleKey: "qrCampaign",
  },
  UPICASHBACK: {
    title: "UPI Cashback",
    moduleKey: "cashbackPayouts",
  },
  SCRATCHCARDMANAGEMENT: {
    title: "Scratch Card Management",
    moduleKey: "scratchCardManagement",
  },
  REFUTEMANAGEMENT: {
    title: "Refute Management",
    moduleKey: "refuteManagement",
  },
  SCANANDEARNMANAGEMENT: {
    title: "Scan and Earn Management",
    moduleKey: "scanAndEarnAriston",
  },
  SHOPANDWIN: { title: "Shop and Win", moduleKey: "scanCampaign" },
  RETAILER: { title: "Retailers", moduleKey: "retailers" },
  CUSTOMERS: { title: "Customers", moduleKey: "customers" },
  BOOKINGMANAGEMENT: {
    title: "Plan Visit Booking",
    moduleKey: "visitorBookingReport",
  },
  BANNERS: { title: "Banners", moduleKey: "banner" },
  ONBORADINGSCREEN: {
    title: "Onboarding Screen",
    moduleKey: "onboardingScreen",
  },
  MALLFEEDMANAGER: { title: "Mall Feed Manager", moduleKey: "mallFeed" },
  RULEENGINER: { title: "Rule Engine", moduleKey: "rule" },
  REWARDMANAGEMENT: {
    title: "Reward Management",
    moduleKey: "rewardManagement",
  },
  PARKING: { title: "Parking Management", moduleKey: "parking" },
  BAGGAGESERVICES: { title: "Baggage Services", moduleKey: "baggage" },
  DINEOUT: { title: "Dineout Orders", moduleKey: "dineout" },
  REFFERAL: {
    title: "Collateral Referral Code Manager",
    moduleKey: "referralCampaign",
  },
  REFFERAL_NEW: {
    title: "Collateral Referral Code Manager New",
    moduleKey: "referralCampaignV2",
  },
  CUSTOMERDELIGHT: {
    title: "Customer Delight Transfer",
    moduleKey: "customerDelight",
  },

  COUPON_REDEMPTION_REPORT: {
    title: "Coupon Redemption Report",
    moduleKey: "couponRedemptionReport",
  },
  REWARD_REDEMPTION_REPORT: {
    title: "Reward Redemption Report",
    moduleKey: "rewardRedemptionReport",
  },
  SCAN_REPORT: { title: "Scan Report", moduleKey: "customerScansReport" },
  CONTACTLESS_PAYMENT_REPORT: {
    title: "Contactless Payment Report",
    moduleKey: "paymentTransaction",
  },
  FAVORITESTOREREPORT: {
    title: "Favourite Store Report",
    moduleKey: "favouriteStoreReport",
  },
  REPORTSMANAGEMENT: {
    title: "Report Management",
    moduleKey: "reportsManagement",
  },
  CUSTOMERBYDOB: { title: "Customers By DOB", moduleKey: "customerByDob" },
  CUSTOMERBYANIVVERSARY: {
    title: "Customers BY Anniversary",
    moduleKey: "customerByAnniversary",
  },

  ISSUEMANAGEMENT: { title: "Issue Management", moduleKey: "issueManagement" },
  DEEPLINK: { title: "Deep Link", moduleKey: "deepLink" },
  FOOTFALLREPORT: { title: "Footfall Report", moduleKey: "footfallReport" },
  SPINWHEEL: { title: "Spin the Wheel", moduleKey: "spinWheel" },
  PERSONALISEDMALLVISIT: {
    title: "Personalised Visit",
    moduleKey: "personalizedInstanceVisit",
  },
  HANDERLARAPP: { title: "Handler Apps", moduleKey: "handlerApp" },
  PHYSICALVOUCHERDISTRIBUTION: {
    title: "Physical Voucher Distribution",
    moduleKey: "conciergePhysicalVoucher",
  },
  OTHERISSUES: { title: "Other Issues", moduleKey: "otherIssues" },
  SCANSERVICE: { title: "Scan Service", moduleKey: "scanService" },
  VOUCHERMANAGEMENTBACKEND: {
    title: "Voucher Management",
    moduleKey: "voucherManagement",
  },
  COMPANIES: { title: "Companies", moduleKey: "companies" },
  CONTROLLERS_APP: { title: "Controllers App", moduleKey: "controllersApp" },
  SUPERUSER: { title: "Super User", moduleKey: "superUser" },
  STATIC_PAGES: { title: "Static Pages", moduleKey: "staticPage" },
  QUIZ: { title: "Quiz", moduleKey: "quiz" },
  NEW_DASHBOARD_DEMO: {
    title: "New Dashboard Demo",
    moduleKey: "newDashboardDemo",
  },
  BRANDING: { title: "Branding", moduleKey: "branding" },
};
