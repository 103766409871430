import {mallActionTypes} from './mall.actiontype';
import {initialState, serverToClientKeyValueMapping} from './mall.initial_state';

export const Mall  = (state = initialState, action = {}) => {
  switch(action.type){

    // Add company
    case mallActionTypes.HANDLE_MALL_INPUT_CHANGE:
      const { key , target} = action.data;
      const { name, value} = target;

      return {
        ...state,
        form: {
          ...state.form,
          [key]: {
            ...state.form[key],
            [name]: {
              ...state.form[key][name],
              input_val: value
            }
          }
        }
      };

      // form errors
      case mallActionTypes.HANDLE_MALL_ERROR_CHANGE:
        if (!action.data.error) {
          return {
            ...state,
            form: {
              ...state.form,
              client_errors: state.form.client_errors.filter(error => error.key !== action.data.name)
            }
          }
        } else {
          let indexIs = state.form.client_errors.findIndex(error => {
            return error.key === action.data.name
          });
          if (indexIs > -1) {
            return {
              ...state,
              form: {
                ...state.form,
                client_errors: [...state.form.client_errors.filter(error => error.key !== action.data.name), action.data.error]
              }
            }
          } else {
            return {
              ...state,
              form: {
                ...state.form,
                client_errors: [...state.form.client_errors, action.data.error]
              }
            }
          }
        }

        
    case mallActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case mallActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case mallActionTypes.SUCCESS_ADD_MALL:
      return {
        ...state,
        form: initialState.form
      }


    case mallActionTypes.FAILED_ADD_MALL:
      if (action.data.error_type === 'CLIENT') {
        return {
          ...state,
          form: {
            ...state.form,
            client_errors: action.data.errors
          }
        }
      } else {
        return {
          ...state,
          form: {
            ...state.form,
            server_errors: action.data.errors
          }
        }
      }

    case mallActionTypes.SUCCESS_FETCH_MALLS:
      return {
        ...state,
        malls: action.data.malls,
        total_malls: action.data.total_malls,
        loading: false
      };

    case mallActionTypes.FAILED_FETCH_MALLS:
      return {
        ...state,
        loading: false,
        error: action.data
      }

    case mallActionTypes.SET_BY_MALL_ID:
      return {
        ...state,
        mall_id: action.data.id,
        form: {
          ...state.form,
          mall_information: {
            ...state.form.mall_information,
            mall_name: {
              ...state.form.mall_information.mall_name,
              input_val: action.data.mallName
            },
            mall_logo: {
              ...state.form.mall_information.mall_logo,
              logoUrl: action.data.mallLogo
            }
          },
          contact_information: {
            ...state.form.contact_information,
            email: {
              ...state.form.contact_information.email,
              input_val: action.data.mallEmail
            },
            country_code_primary: {
              ...state.form.contact_information.country_code_primary,
              input_val: action.data.countryCodePrimary
            },
            contact_number: {
              ...state.form.contact_information.contact_number,
              input_val: action.data.mallPrimaryContact
            },
            country_code_secondary: {
              ...state.form.contact_information.country_code_secondary,
              input_val: action.data.countryCodeSecondary
            },
            secondary_number: {
              ...state.form.contact_information.secondary_number,
              input_val: action.data.mallSecondaryContact
            }
          },
          mailing_address: {
            ...state.form.mailing_address,
            address_line_1: {
              ...state.form.mailing_address.address_line_1,
              input_val: action.data.mallAddress1
            },
            address_line_2: {
              ...state.form.mailing_address.address_line_2,
              input_val: action.data.mallAddress2
            },
            city: {
              ...state.form.mailing_address.city,
              input_val: action.data.mallCity
            },
            state: {
              ...state.form.mailing_address.state,
              input_val: action.data.mallState
            },
            zip_code: {
              ...state.form.mailing_address.zip_code,
              input_val: action.data.mallPincode
            },
          }
        },
        mallFeatures: action.data.mallFeatures,
        companyAdmins: action.data.companyAdmins
      };

    case mallActionTypes.SUCCESS_EDIT_MALL:
      return {
        ...state,
        form: {
          ...state.form,
          server_success: action.data
        }
      }

    case mallActionTypes.SET_MALL_LOGO:
      return {
        ...state,
        form: {
          ...state.form,
          mall_information: {
            ...state.form.mall_information,
            mall_logo: {
              ...state.form.mall_information.mall_logo,
              logoUrl: action.data
            }
          }
        }
      }

    case mallActionTypes.CLEAR_ADD_MALL_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          mall_information: {
            ...state.form.mall_information,
            mall_name: {
              ...state.form.mall_information.mall_name,
              input_val: ''
            },
            mall_logo: {
              ...state.form.mall_information.mall_logo,
              logoUrl: ''
            }
          },
          contact_information: {
            ...state.form.contact_information,
            email: {
              ...state.form.contact_information.email,
              input_val: ''
            },
            country_code_primary: {
              ...state.form.contact_information.country_code_primary,
              input_val: '129'
            },
            contact_number: {
              ...state.form.contact_information.contact_number,
              input_val: ''
            },
            country_code_secondary: {
              ...state.form.contact_information.country_code_secondary,
              input_val: '129'
            },
            secondary_number: {
              ...state.form.contact_information.secondary_number,
              input_val: ''
            }
          },
          mailing_address: {
            ...state.form.mailing_address,
            address_line_1: {
              ...state.form.mailing_address.address_line_1,
              input_val: ''
            },
            address_line_2: {
              ...state.form.mailing_address.address_line_2,
              input_val: ''
            },
            city: {
              ...state.form.mailing_address.city,
              input_val: ''
            },
            state: {
              ...state.form.mailing_address.state,
              input_val: ''
            },
            zip_code: {
              ...state.form.mailing_address.zip_code,
              input_val: ''
            },
          },
          client_errors: [],
          server_errors: '',
          server_success: ''
        }
      };


    default:
      return state;
  }
}