import React from "react";

const MobileScanEarn = ({ color, width, height }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mobile-scan-earn"
    >
      <path
        d="M19.1373 14.7837V19.3045H2.8623V14.7837H4.67064V17.4962H17.329V14.7837H19.1373ZM2.8623 10.2629H19.1373V12.0712H2.8623V10.2629ZM19.1373 7.55037H17.329V4.83787H4.67064V7.55037H2.8623V3.02954H19.1373V7.55037Z"
        fill={color || "#3A66D9"}
      />
    </svg>
  );
};

export default MobileScanEarn;
