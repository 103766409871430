import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { appStoreActiontype } from "./appStore.actiontype";
import { appConstSuccess } from "./appStore.action";
import { appService } from "../../service";

export function* handleAppConstInit() {
  try {
    const mallId = select((state) => state.modulesAndMalls.selectedMall);
    // if (mallId) {
      const [appConstant] = yield Promise.all([appService.getAppConstant()]);
      yield all([put(appConstSuccess(appConstant))]);
    // }
  } catch (e) {
    console.log(e);
  }
}

export function* appConstantSaga() {
  yield takeLatest(appStoreActiontype.APPCONSTANT_INIT, handleAppConstInit);
}

export function* appStoreSaga() {
  yield all([call(appConstantSaga)]);
}
