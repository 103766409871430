export const urlsMappings = {
  notifications: "/notifications",
  deepLink: "/deep-link-management",
  referral: "/referral",
  referralNew: "/revamp",
  banner: "/banner",
  onboardingScreens: "/onboarding-screens",
  mallFeedManagers: "/feed-managers",
  customerManagement: "/customer-management",
  refuteManagement: "/refute-management",
  scanAndEarnManagement: "/scan-and-earn-management",
  couponManagement: "/coupon-management",
  couponManagementFull: "/coupon-management-full",
  qrCodeGeneration: "/qr-code-generation",
  upiCashback: "/upi-cashback",
  scratchCardManagement: "/scratch-card-management",
  ruleEngine: "/rule-engine",
  rewardManagement: "/reward-management",
  shopAndWin: "/shop-and-win",
  spinTheWheel: "/spin-the-wheel",
  customerDelight: "/customer-delight",
  retailers: "/retailers",
  userManagement: "/user-management",
  bookingManagement: "/booking-management",
  parking: "/parking",
  baggageServices: "/baggage-services",
  dineout: "/dineout",
  scanReports: "/scan-reports",
  couponRedemption: "/coupon-redemption",
  rewardRedemption: "/reward-redemption",
  contactlessPaymentLogReport: "/contactless-payment-log-report",
  reportList: "/report-list",
  favStoreReportList: "/fav-store-report-list",
  customersByDobList: "/customers-by-dob-list",
  customersByAnniversaryList: "/customers-by-anniversary-list",
  otherIssues: "/other-issues",
  issueManagement: "/issue-management",
  conciergePhysicalDistributionCouponVouchers:
    "/concierge-physical-distribution-coupon-vouchers",
  conciergePhysicalDistributionRewardVouchers:
    "/concierge-physical-distribution-reward-vouchers",
  personalizedMallVisit: "/personalised-plan-visit",
  handler: "/handler",
  scanService: "/scan-service",
  voucherMgmt: {
    inward: "/voucher/backend/inwards",
    outward: "/voucher/backend/outwards",
    retailers: "/voucher/backend/voucher-retailer",
  },
  frontdeskMgmt: {
    inward: "/voucher/frontdesk/inwards",
    outward: "/voucher/frontdesk/outwards",
  },
  quiz: "/quiz",
  branding: "/branding",
};

export const menuUrlMapping = {
  "/notifications": {
    menu: "communication",
    subMenu: "notificationCenter",
  },
  "/deep-link-management": {
    menu: "communication",
    subMenu: "deepLink",
  },
  "/referral": {
    menu: "communication",
    subMenu: "referralCampaign",
  },
  "/revamp": {
    menu: "communication",
    subMenu: "referralCampaignV2",
  },
  "/quiz": {
    menu: "loyalty",
    subMenu: "quiz",
  },
  "/banner": {
    menu: "content",
    subMenu: "banner",
  },
  "/onboarding-screens": {
    menu: "content",
    subMenu: "onboardingScreen",
  },
  "/feed-managers": {
    menu: "content",
    subMenu: "mallFeed",
  },
  "/customer-management": {
    menu: "customers",
  },
  "/refute-management": {
    menu: "loyalty",
    subMenu: "refuteManagement",
  },
  "/scan-and-earn-management": {
    menu: "loyalty",
    subMenu: "scanAndEarnAriston",
  },
  "/coupon-management": {
    menu: "loyalty",
    subMenu: "couponManagementPro",
  },
  "/coupon-management-full": {
    menu: "loyalty",
    subMenu: "couponManagementPro",
  },
  "/qr-code-generation": {
    menu: "loyalty",
    subMenu: "qrCampaign",
  },
  "/upi-cashback": {
    menu: "loyalty",
    subMenu: "cashbackPayouts",
  },
  "/scratch-card-management": {
    menu: "loyalty",
    subMenu: "scratchCard",
  },
  "/rule-engine": {
    menu: "loyalty",
    subMenu: "rule",
  },
  "/reward-management": {
    menu: "loyalty",
    subMenu: "rewardManagement",
  },
  "/shop-and-win": {
    menu: "loyalty",
    subMenu: "scanCampaign",
  },
  "/spin-the-wheel": {
    menu: "loyalty",
    subMenu: "spinWheel",
  },
  "/customer-delight": {
    menu: "loyalty",
    subMenu: "customerDelight",
  },

  "/retailers": {
    menu: "retailers",
  },

  "/user-management": {
    menu: "userManagement",
  },
  "/booking-management": {
    menu: "bookingManagement",
  },
  "/parking": {
    menu: "parking",
  },
  "/baggage-services": {
    menu: "baggage",
  },
  "/dineout": {
    menu: "dineout",
  },
  "/scan-reports": {
    menu: "reports",
    subMenu: "rewardRedemptionReport",
  },
  "/coupon-redemption": {
    menu: "reports",
    subMenu: "couponRedemptionReport",
  },
  "/reward-redemption": {
    menu: "reports",
    subMenu: "rewardRedemptionReport",
  },
  "/contactless-payment-log-report": {
    menu: "reports",
    subMenu: "paymentTransaction",
  },
  "/report-list": {
    menu: "reports",
    subMenu: "reportsManagement",
  },
  "/fav-store-report-list": {
    menu: "reports",
    subMenu: "favouriteStoreReport",
  },
  "/customers-by-dob-list": {
    menu: "reports",
    subMenu: "customerByDob",
  },
  "/customers-by-anniversary-list": {
    menu: "reports",
    subMenu: "customerByAnniversary",
  },
  "/voucher": {
    menu: "content",
    subMenu: "banner",
  },
  "/other-issues": {
    menu: "concierge",
    subMenu: "conciergeIssue",
  },
  "/issue-management": {
    menu: "issueManagement",
    // subMenu: 'issueManagement'
  },
  "/concierge-physical-distribution-coupon-vouchers": {
    menu: "concierge",
    subMenu: "conciergePhysicalVoucher",
  },
  "/concierge-physical-distribution-reward-vouchers": {
    menu: "concierge",
    subMenu: "conciergePhysicalVoucher",
  },
  "/personalised-plan-visit": {
    menu: "loyalty",
    subMenu: "personalizedInstanceVisit",
  },
  "/handler": {
    menu: "handlerApp",
  },
  "/scan-service": {
    menu: "concierge",
    subMenu: "conciergeScan",
  },
  "/branding": {
    menu: "branding",
  },
};
