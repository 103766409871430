/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function BreadCrumbs({ items }) {
  const pageHeading = useSelector((state) => state.appData.pageHeading);

  useEffect(() => {
    if (!items || !items.length) {
      items.push(pageHeading);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      <Link className="breadcrumb-item" to="/new/overview">
        Dashboard
      </Link>
      {items.map((item, index) => (
        <li className="breadcrumb-item" key={index}>
          <Link className="text-muted " to={{ pathname: item?.pathname }}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
