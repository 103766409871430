import React, { useEffect, useState } from "react";
import Select from "react-select";
import { brandingService } from "../../../service/api/branding.service";

const FontField = ({ formData, setFormData }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await brandingService.getGoogleFonts();
        const selectOptions = response.data.items.map((item) => ({
          label: item.family,
          value: item.family,
        }));
        setOptions(selectOptions);
      } catch (e) {
        console.error("Get google fonts", e);
      }
    })();
  }, []);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hides the indicator separator
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#181C32", // Change the color based on the focused state
      "&:hover": {
        color: "#181C32", // Change color on hover
      },
    }),
    // You can override other styles here if needed
  };

  return (
    <div className="font-family-ctn">
      <div className="form-subtitle-text">
        Select from an array of Google Fonts
      </div>
      <Select
        options={options}
        placeholder="Select"
        className={"font-family-select"}
        value={{ label: formData.fontFamily, value: formData.fontFamily }}
        onChange={(value) => {
          console.log("value", value);
          setFormData((prev) => ({ ...prev, fontFamily: value.value }));
        }}
        styles={customStyles}
      />
    </div>
  );
};

export default FontField;
