import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { mmActiontypes } from "./mm.actionTypes";

const INIT_STATE = {
  modulesList: undefined,
  menuObj: undefined,
  menuPermission: undefined, // this will contain flat array of menuObj
  modulesPermitted: [],
  mallsList: [],
  selectedMall: undefined,
  apiProcess: false,
  mallSwitchModalOpen: false,
  modulesMallsError: undefined,
  currentPage: "",
  CRUDStatus: true,
};

const handlerAppObj = {
  // handlerApp: 2,
  companies: 2,
  // controllersApp: 2,
  // content: {subMenu: {banner: 2}},
  // loyalty: {subMenu: {couponManagementPro: }}
};

const objToflatObj = (obj, start) => {
  return Object.keys(obj).reduce(
    (curr, menuItem) => {
      return typeof obj[menuItem] === "object"
        ? objToflatObj(obj[menuItem], curr)
        : { ...curr, [menuItem]: obj[menuItem] };
    },
    start ? start : {}
  );
};

const modulesMallReducer = persistReducer(
  {
    storage,
    key: "userModulesMalls",
    whitelist: ["modulesPermitted", "mallsList", "selectedMall"],
  },
  (state = INIT_STATE, { type, payload }) => {
    switch (type) {
      case mmActiontypes.MODULES_SUCCESS:
        return {
          ...state,
          modulesList: payload.modulesList,
          menuObj: { ...payload.menuObj, ...handlerAppObj },
          menuPermission: objToflatObj(payload.menuObj),
        };
      case mmActiontypes.MM_INITALDATA:
        console.log("payload is ", payload);
        const { instances, instanceId, permissions } = payload;
        const _mallId = String(instanceId) === "0" ? null : `${instanceId}`;
        return {
          ...state,
          mallsList: instances,
          selectedMall: (_mallId && _mallId.split(",")[0]) || _mallId,
          modulesPermitted: permissions,
        };

      case mmActiontypes.MM_SET_MALLS:
        return {
          ...state,
          mallsList: payload,
        };

      case mmActiontypes.MALL_SWITCH_TOGGLE:
        return {
          ...state,
          mallSwitchModalOpen: !state.mallSwitchModalOpen,
        };
      case mmActiontypes.MALL_SWITCH_INIT:
        return {
          ...state,
          selectedMall: payload,
          apiProcess: true,
        };
      case mmActiontypes.MALL_SWITCH_SUCCESS:
        return {
          ...state,
          apiProcess: false,
        };
      case mmActiontypes.MM_ERROR:
        return {
          ...state,
          apiProcess: false,
          modulesMallsError: payload,
        };
      case mmActiontypes.MM_CRUD_STATUS:
        const bool =
          state.menuPermission[payload] && state.menuPermission[payload] > 1;
        return {
          ...state,
          CRUDStatus: bool,
        };
      case mmActiontypes.SET_MALL_ID:
        return {
          ...state,
          selectedMall: payload,
        };
      case mmActiontypes.LOGOUT:
        return INIT_STATE;
      default:
        return state;
    }
  }
);

export default modulesMallReducer;
