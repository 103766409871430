import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { createStructuredSelector } from "reselect";
import { NavLink, Link } from "react-router-dom";
import { appMenu } from "../../store/modules_mallsStore/mm.select";
import menuConfig from "../../router/MenuConfig";
import { useEffect } from "react";
import { addMenuConfig } from "../../store/menuSearch/menu.action";
import MenuItems from "./menuItems";
import MallMenuList from "./mallMenu";
import { HTTPClient } from "../../service";
import {
  setMallId,
  malls_switch_init_malls,
} from "../../store/modules_mallsStore/mm.actions";

const MenuList = (props) => {
  const {
    layoutProps,
    appMenu,
    getMenuItemActive,
    setMallId,
    setMalls,
    history,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  let menuConfigState = useSelector((state) => state.MenuSearch.menuConfig);
  const userType = useSelector(
    ({
      user: {
        userData: { userType },
      },
    }) => userType
  );

  const selectedMall = useSelector(
    ({ modulesAndMalls }) => modulesAndMalls.selectedMall
  );
  const openDropdown = (key) => {
    let urlKey = location.pathname.split("/");
    let locationKey = urlKey[1];
    let openModule = [];
    let indexKey = null;
    menuConfigState.items.forEach((child, index) => {
      if (child.submenu) {
        openModule = child.submenu.filter((ele) => ele.page === locationKey);
        if (openModule.length > 0) {
          indexKey = index;
          let nameKey = menuConfigState.items[indexKey].key;
          let parentClass = document.getElementsByTagName(`li`);
          if (parentClass !== null && parentClass.length > 0) {
            setTimeout(() => {
              for (let i = 0; i < 50; i++) {
                if (parentClass.item(i) !== null) {
                  if (parentClass.item(i).id === nameKey) {
                    // console.log(parentClass.item(i))
                    // parentClass.item(i).classList.add(`menu-item-active`);
                    // parentClass.item(i).classList.add(`menu-item-open`);
                    parentClass.item(i).className += `${true &&
                      "menu-item-active"} menu-item-open`;
                  }
                }
              }
            }, 1000);
          }
        }
      }
    });
  };

  const updateMallId = () => {
    // HTTPClient.deleteHeader('mallId');
    history.push(`/`);
    HTTPClient.saveHeader({ key: "instanceId", value: 0 });

    setTimeout(() => {
      setMallId(0);
    });
    setMalls([]);
  };
  useEffect(() => {
    dispatch(addMenuConfig(menuConfig));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (Object.keys(menuConfigState).length > 0) {
      openDropdown();
    }
  }, [menuConfigState]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {appMenu ? (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {((userType === 2 && selectedMall) ||
              userType === 3 ||
              userType === 5) && (
              <MallMenuList
                layoutProps={layoutProps}
                appMenu={appMenu}
                getMenuItemActive={getMenuItemActive}
              />
            )}
            <li
              key={"Dashboard"}
              className={`menu-item ${getMenuItemActive(
                `/new/overview`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/new/overview">
                <span className="svg-icon menu-icon">
                  <i className="fas fa fa-home"></i>
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            {/*
              <li
                  key={'Old Dashboard'}
                  className={`menu-item ${getMenuItemActive(
                    `/new/overview`, false
                  )}`}
                  aria-haspopup="true"
                >
                <NavLink className="menu-link" to="/new/overview">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa fa-home'></i>
                  </span>
                    <span className="menu-text">Old Dashboard</span>
                </NavLink>
              </li> */}
            {(userType === 2 && selectedMall) ||
            userType === 3 ||
            userType === 4 ||
            userType === 5
              ? menuConfigState.items.map((child, index) => {
                  return (
                    <>
                      {child.title && !child.admin && appMenu[child.key] && (
                        <MenuItems
                          child={child}
                          appMenu={appMenu}
                          getMenuItemActive={getMenuItemActive}
                        />
                      )}
                    </>
                  );
                })
              : menuConfigState.items.map((child, index) => {
                  return (
                    <>
                      {child.title && child.admin && appMenu[child.key] && (
                        <MenuItems
                          child={child}
                          appMenu={appMenu}
                          getMenuItemActive={getMenuItemActive}
                        />
                      )}
                    </>
                  );
                })}
            {/* <li
                  key={'handler'}
                  className={`menu-item ${getMenuItemActive(
                    `/handler`, false
                  )}`}
                  aria-haspopup="true"
                >
                <NavLink className="menu-link" to="/handler">
                  <span className="svg-icon menu-icon">
                    <i className='fas fa fa-mobile'></i>
                  </span>
                    <span className="menu-text">Handler App</span>
                </NavLink>
              </li> */}
          </ul>
          {userType === 2 && selectedMall ? (
            <Link className="menu-link" onClick={() => updateMallId()}>
              <span className="menu-text">Go to console</span>
            </Link>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapDispatchTopProps = (dispatch) => {
  return {
    setMallId: (mallId) => dispatch(setMallId(mallId)),
    setMalls: (malls) => dispatch(malls_switch_init_malls(malls)),
  };
};

const mapStateToProps = createStructuredSelector({
  appMenu,
});
export default connect(mapStateToProps, mapDispatchTopProps)(MenuList);
