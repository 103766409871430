export const initialState = {
  mall_id: null,
  form: {
    mall_information: {
      mall_name: {
        input_val: '',
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 75
        }
      },
      mall_logo: {
        input_val: '',
        logoUrl: '',
        required: false,
        type: File,
        condition: {
          size: 2, // in mb
          dimensions: {
            height: 250,
            width: 250
          },
          type: 'png|jpeg|jpg'
        }
      }
    },
    contact_information: {
      email: {
        input_val: '',
        required: true,
        type: {
          name: 'Email'
        },
        condition: {
          min: 1,
          max: 250
        }
      },
      country_code_primary: {
        input_val: '129',
        required: true,
        type: {
          name: 'Select'
        },
        condition: {
          min: 4,
          max: 15
        }
      },
      contact_number: {
        input_val: '',
        required: true,
        type: Number,
        condition: {
          min: 4,
          max: 15
        }
      },
      country_code_secondary: {
        input_val: '',
        required: false,
        type: {
          name: 'Select'
        },
        condition: {
          min: 4,
          max: 15
        }
      },
      secondary_number: {
        input_val: '',
        required: false,
        type: Number,
        condition: {
          min: 4,
          max: 15
        }
      }
    },
    mailing_address: {
      address_line_1: {
        input_val: '',
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 100
        }
      },
      address_line_2: {
        input_val: '',
        required: false,
        type: String,
        condition: {
          min: 1,
          max: 100
        }
      },
      city: {
        input_val: '',
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 50
        }
      },
      state: {
        input_val: '',
        required: true,
        type: String,
        condition: {
          min: 1,
          max: 50
        }
      },
      zip_code: {
        input_val: '',
        required: true,
        type: Number,
        condition: {
          min: 6,
          max: 6
        }
      },
    },
    client_errors: [],
    server_errors: '',
    server_success: '',
  },
  loading: false,
  malls: [],
  total_malls: 0,
  error: '',
  companyAdmins: [],
  companyFeatures: []
};

export const serverToClientKeyValueMapping = {
  mall_information: {
    mallName: 'mall_name',
    mallLogo: 'mall_logo'
  },
  contact_information: {
    mallEmail: 'email',
    countryCodePrimary: 'country_code_primary',
    mallPrimaryContact: 'contact_number',
    countryCodeSecondary: 'country_code_secondary',
    mallSecondaryContact: 'secondary_number'
  },
  mailing_address: {
    mallAddress1: 'address_line_1',
    mallAddress2: 'address_line_2',
    mallCity: 'city',
    mallState: 'state',
    mallPincode: 'zip_code'
  }
}