import {HTTPClient} from './http.service';

export const technicalDetails = {
  async getTechnicalDetails(mallId) {
    try {
      const result = await HTTPClient.get(`/settings/instance/getTechnicalDetails/${mallId}`);
      return result;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async addTechnicalDetails(mallId, data) {
    try {
      const response = await HTTPClient.post(`/settings/instance/addTechnicalDetails/${mallId}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async updateTechnicalDetails(mallId, data) {
		try {
			let result = await HTTPClient.put(`/settings/instance/updateTechnicalDetails/${mallId}`, data);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
}
