import React from "react";
import DropDownIcon from "../assets/chevron-down.svg";

const ColorField = ({ formData, setFormData }) => {
  const handleColorChange = (event) => {
    const hexValue = event.target.value;
    if (hexValue) {
      setFormData((prev) => ({ ...prev, colour: hexValue }));
    }
  };

  return (
    <div className="color-field-ctn">
      <div className="form-subtitle-text">Select your primary brand color</div>
      <label className="color-field">
        <span
          className="preview-color"
          style={{ backgroundColor: formData.colour }}
        ></span>
        <span className="hex-code">{formData.colour}</span>
        <img src={DropDownIcon} alt="down arrow" />
        <input
          type="color"
          id="color"
          value={formData.colour}
          onChange={handleColorChange}
          className="input-color"
        />
      </label>
    </div>
  );
};

export default ColorField;
