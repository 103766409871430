
import {combineReducers} from 'redux';

// import { metronic } from "../../_metronic";
import modulesMallReducer from './modules_mallsStore/mm.reducer';
import userReducer from "./userStore/user.reducer";
import appDataReducer from './appStore/appStore.reducer';
import authReducer from './auth/auth.reducer';
import {Notification} from './notification/notification.reducer'
import {RetailerCategory} from './retailer/category/category.reducer'
import {RetailerShopNumber} from './retailer/shopNumber/shopNumber.reducer'
import {Retailer} from './retailer/retailer.reducer'
import {BookingManagement} from './bookingManagement/bookingManagement.reducer'
import Customer from './customer/customer.reducer';
import MallFeed from './mallFeed/mallFeed.reducer';
import MenuSearch from './menuSearch/menu.reducer';
import {Refute} from './refute/refute.reducer';
import {Coupon} from './coupon/coupon.reducer';
import {Reward} from './reward/reward.reducer';
import {CouponReport} from './reports/coupon/coupon.reducer';
import {RewardReport} from './reports/reward/reward.reducer';
import { Company } from './company/company.reducer'
import { controllerApp } from './controllerApp/controllerApp.reducer'
import { Mall } from './mall/mall.reducer'
import { controlCenter } from './controlCenter/controlCenter.reducer'

const rootReducer = combineReducers({
  appData: appDataReducer,
  auth: authReducer,
  user:  userReducer,
  Customer,
  modulesAndMalls: modulesMallReducer,
  Notification,
  RetailerCategory,
  RetailerShopNumber,
  Retailer,
  BookingManagement,
  MallFeed,
  MenuSearch,
  Refute,
  Coupon,
  Reward,
  CouponReport,
  RewardReport,
  Company,
  controllerApp,
  Mall,
  controlCenter
});

export default rootReducer;
