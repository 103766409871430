import {mallActionTypes} from './mall.actiontype';
export const actions = {
  handleInputChange: object => ({ type: mallActionTypes.HANDLE_MALL_INPUT_CHANGE, data: object }),
  handleMallErrorChange: data => ({ type: mallActionTypes.HANDLE_MALL_ERROR_CHANGE, data: data }),
  request: () => ({ type: mallActionTypes.REQUEST }),
  complete: () => ({ type: mallActionTypes.COMPLETE }),
  successAddMall: () => ({type: mallActionTypes.SUCCESS_ADD_MALL}),
  failedAddMall: (error) => ({type: mallActionTypes.FAILED_ADD_MALL, data: error}),
  setMallById: (data) => ({ type: mallActionTypes.SET_BY_MALL_ID, data}),
  successEditMall: ({data}) => ({ type: mallActionTypes.SUCCESS_EDIT_MALL, data}),


  setMallLogo: (base64) => ({type: mallActionTypes.SET_MALL_LOGO, data: base64})
};