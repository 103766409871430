import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./brandingStyle.scss";
import FormWrapper from "./components/FormWrapper";
import CompanyLogoField from "./FormFields/CompanyLogoField";
import ColorField from "./FormFields/ColorField";
import FontField from "./FormFields/FontField";
import RoundedField from "./FormFields/RoundedField";
import SignupImageField from "./FormFields/SignupImageField";
import FaviconField from "./FormFields/FaviconField";
import BannerImageField from "./FormFields/BannerImageField";
import { brandingService } from "../../service/api/branding.service";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import PreviewIcon from "./assets/preview.svg";
import DesktopView from "./assets/DesktopView";
import MobileView from "./assets/MobileView";
import Preview from "./preview";
import DesktopModalPreview from "./ModalPreview/DesktopModalPreview";
import MobileModalPreview from "./ModalPreview/MobileModalPreview";

const Branding = () => {
  const [formData, setFormData] = useState({
    desktopCompanyLogo: { name: "", preview: "", file: null },
    mobileCompanyLogo: { name: "", preview: "", file: null },
    signupImage: { name: "", preview: "", file: null },
    favicon: { name: "", preview: "", file: null },
    desktopBannerImage: { name: "", preview: "", file: null },
    mobileBannerImage: { name: "", preview: "", file: null },
    colour: "#1bc5bd",
    defaultHeaderBackground: "#1bc5bd",
    fontFamily: "Poppins",
    isRounded: 0,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isBannerReset, setIsBannerReset] = useState(false);
  const [isPreviewDesktop, setIsPreviewDesktop] = useState(true);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const MySwal = withReactContent(Swal);

  const isResetToDefaultDisabled =
    !formData.desktopCompanyLogo.preview &&
    !formData.mobileCompanyLogo.preview &&
    !formData.signupImage.preview &&
    !formData.favicon.preview;

  useEffect(() => {
    (async () => {
      try {
        const response = await brandingService.getBranding();
        const data = response?.data?.data;
        setFormData((prev) => ({
          ...prev,
          desktopCompanyLogo: {
            name: "",
            preview: data.companyLogoDesktop,
            file: null,
          },
          mobileCompanyLogo: {
            name: "",
            preview: data.companyLogoMobile,
            file: null,
          },
          signupImage: {
            name: "",
            preview: data.signupImageDesktop,
            file: null,
          },
          favicon: { name: "", preview: data.favicon, file: null },
          desktopBannerImage: {
            name: "",
            preview: data.bannerImageDesktop,
            file: null,
          },
          mobileBannerImage: {
            name: "",
            preview: data.bannerImageMobile,
            file: null,
          },
          colour: data.colour,
          fontFamily: data.fontFamily,
          isRounded: data.isRounded,
        }));
        setTimeout(() => {
          setIsSaveDisabled(true);
        }, 0);
      } catch (e) {
        console.error("Get branding", e);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      !formData.desktopCompanyLogo.error &&
      formData.desktopCompanyLogo.preview &&
      !formData.mobileCompanyLogo.error &&
      formData.mobileCompanyLogo.preview &&
      !formData.signupImage.error &&
      formData.signupImage.preview &&
      !formData.favicon.error &&
      !formData.mobileBannerImage.error &&
      !formData.desktopBannerImage.error &&
      formData.colour &&
      formData.fontFamily
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [formData]);

  const handleReset = () => {
    setFormData({
      desktopCompanyLogo: { name: "", preview: "", file: null },
      mobileCompanyLogo: { name: "", preview: "", file: null },
      signupImage: { name: "", preview: "", file: null },
      favicon: { name: "", preview: "", file: null },
      desktopBannerImage: { name: "", preview: "", file: null },
      mobileBannerImage: { name: "", preview: "", file: null },
      colour: "#1bc5bd",
      defaultHeaderBackground: "#1bc5bd",
      fontFamily: "Poppins",
      isRounded: 0,
    });
  };

  const handleSaveBranding = async () => {
    setIsSaveDisabled(true);
    try {
      const themeStyle = {
        color: formData.colour,
        fontFamily: formData.fontFamily,
        isRounded: formData.isRounded,
      };
      let data = new FormData();
      data.append("companyLogoMobile", formData.mobileCompanyLogo.file);
      data.append("companyLogoDesktop", formData.desktopCompanyLogo.file);
      data.append("signUpImage", formData.signupImage.file);
      data.append("favicon", formData.favicon.file);
      data.append("bannerImageMobile", formData.mobileBannerImage.file);
      data.append("bannerImageDesktop", formData.desktopBannerImage.file);
      data.set("themeStyle", JSON.stringify(themeStyle));
      const response = await brandingService.saveBranding(data);
      MySwal.fire(`Branding added successfully`, "", "success");
    } catch (e) {
      console.error("save branding");
    }
  };

  const handleCloseModal = () => {
    setIsPreviewModal(false);
  };

  return (
    <div className="card card-custom branding">
      <div className="card-header">
        <h3 className="card-label">Branding</h3>
        <div className="right-side-menu">
          <Button
            variant="secondary"
            style={{ marginRight: "10px" }}
            onClick={handleReset}
            disabled={isResetToDefaultDisabled}
          >
            Reset to default
          </Button>
          <Button
            className="ufyndBtn ufyndBtn_primary"
            color="primary"
            onClick={handleSaveBranding}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="ufyndWrapper branding-ctn">
        {/* Form */}
        <div className="branding-form">
          <FormWrapper title={"Company Logo"} isRequired={true}>
            <CompanyLogoField formData={formData} setFormData={setFormData} />
          </FormWrapper>
          <FormWrapper title={"Signup Image"} isRequired={true}>
            <SignupImageField formData={formData} setFormData={setFormData} />
          </FormWrapper>
          <FormWrapper title={"Favicon"} isRequired={true}>
            <FaviconField formData={formData} setFormData={setFormData} />
          </FormWrapper>
          <FormWrapper
            title={"Banner Image"}
            isReset={true}
            setIsBannerReset={setIsBannerReset}
          >
            <BannerImageField
              formData={formData}
              setFormData={setFormData}
              isBannerReset={isBannerReset}
              setIsBannerReset={setIsBannerReset}
            />
          </FormWrapper>
          <FormWrapper title={"Colors"}>
            <ColorField formData={formData} setFormData={setFormData} />
          </FormWrapper>
          <FormWrapper title={"Font"}>
            <FontField formData={formData} setFormData={setFormData} />
          </FormWrapper>
          <FormWrapper title={"Containers"}>
            <RoundedField formData={formData} setFormData={setFormData} />
          </FormWrapper>
        </div>
        {/* Preview */}
        <div className="branding-preview">
          <div className="header">
            <span className="title">Preview</span>
            <span className="cta-ctn">
              <Button
                className={`ufyndBtn ${
                  isPreviewDesktop ? "preview-active" : "preview-deactive"
                }`}
                onClick={() => setIsPreviewDesktop(true)}
              >
                <DesktopView color={isPreviewDesktop ? "#fff" : "#181C32"} />
              </Button>
              <Button
                className={`ufyndBtn ${
                  !isPreviewDesktop ? "preview-active" : "preview-deactive"
                }`}
                onClick={() => setIsPreviewDesktop(false)}
              >
                <MobileView color={!isPreviewDesktop ? "#fff" : "#181C32"} />
              </Button>
            </span>
            <img
              src={PreviewIcon}
              alt="preview"
              className="preview-icon"
              onClick={() => setIsPreviewModal(true)}
            />
          </div>
          <div className="preview-ctn">
            <Preview formData={formData} isPreviewDesktop={isPreviewDesktop} />
          </div>
        </div>
      </div>
      {isPreviewDesktop ? (
        <DesktopModalPreview
          isPreviewModal={isPreviewModal}
          handleCloseModal={handleCloseModal}
          formData={formData}
          isPreviewDesktop={isPreviewDesktop}
        />
      ) : (
        <MobileModalPreview
          isPreviewModal={isPreviewModal}
          handleCloseModal={handleCloseModal}
          isPreviewDesktop={isPreviewDesktop}
          formData={formData}
        />
      )}
    </div>
  );
};

export default Branding;
