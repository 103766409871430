import React from "react";
import DefaultLogo from "../assets/default-logo.svg";
import MenuFeedIcon from "../assets/menu-feed.svg";
import MenuScanIcon from "../assets/menu-scan.svg";
import MenuOffersIcon from "../assets/menu-offers.svg";
import MenuLoyaltyIcon from "../assets/menu-loyalty.svg";
import MenuProfile from "../assets/MenuProfile";
import MenuHome from "../assets/MenuHome";
import MenuSearch from "../assets/MenuSearch";
import MenuNotification from "../assets/MenuNotification";

const menuLinkData = [
  {
    id: 1,
    title: "Home",
    icon: "",
  },
  {
    id: 2,
    title: "Feed",
    icon: MenuFeedIcon,
  },
  {
    id: 3,
    title: "Scan & Earn",
    icon: MenuScanIcon,
  },
  {
    id: 4,
    title: "Offers",
    icon: MenuOffersIcon,
  },
  {
    id: 5,
    title: "Loyalty Hub",
    icon: MenuLoyaltyIcon,
  },
];

const TopHeader = ({ isPreviewDesktop, formData }) => {
  return (
    <div className="top-header">
      <div className="logo">
        {isPreviewDesktop ? (
          <img
            src={
              formData.desktopCompanyLogo?.preview
                ? formData.desktopCompanyLogo?.preview
                : DefaultLogo
            }
            alt="company desktop logo"
            className="logo-image"
          />
        ) : (
          <img
            src={
              formData.mobileCompanyLogo.preview
                ? formData.mobileCompanyLogo.preview
                : DefaultLogo
            }
            alt="company mobile logo"
            className="logo-image"
          />
        )}
      </div>
      <div className="menu-ctn">
        {menuLinkData.map((item) => (
          <div key={item.id} className="menu-link">
            {item.id === 1 ? (
              <MenuHome color={formData.colour} className="menu-icon" />
            ) : (
              <img src={item.icon} alt={item.title} className="menu-icon" />
            )}
            <span
              className="title"
              style={{ color: item.id === 1 ? formData.colour : "" }}
            >
              {item.title}
            </span>
          </div>
        ))}
      </div>
      <div className="right-nav">
        <MenuSearch
          color={isPreviewDesktop ? "#1c1c1c" : "#fff"}
          className="right-nav-icon"
        />
        <MenuNotification
          color={isPreviewDesktop ? "#1c1c1c" : "#fff"}
          className="right-nav-icon"
        />
        <MenuProfile color={formData.colour} className="profile-icon" />
      </div>
    </div>
  );
};

export default TopHeader;
