// underscore (_) separated key to space separated name for headings
export const formateKeyName = (key) => {
  if (!key) return;
  let formatted_key = []
  for (let k of key.split('_')) {
	formatted_key.push(k[0].toUpperCase() + k.slice(1, k.length))
  }
  return formatted_key.join(' ');
}

// export const formateNameKey = (key) => {
//   if (!key) return;
//   return key.split(' ').join('_').toLowerCase();
// }
// space separated text to underscore (_) separated key name
export const formateNameKey = (key) => {
  if (!key) return;
  return key.split(' ').join('_').toLowerCase();
}

export const showInputFieldError = (errors, key) => {
  if (!Array.isArray(errors)) return;
  let indexIs = errors.findIndex((error) => {
	return error.key === key
  })
  if (indexIs > -1) {
   return errors[indexIs].msg;
  }
  return;
}

export const convertFileToBase64 = file => new Promise((resolve, reject) => {
  if (!file) {
	resolve({
	  base64: ''
	});
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({
	base64: reader.result
  });
  reader.onerror = reject;
});

export const getBase64FileExtension = encoded => {
  if (typeof encoded === 'string') {
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      return mime[1];
    }
  }
  return null
};

export const nextDay = (d) => {
  let date = new Date(d);
  date = date.setDate(date.getDate() + 1)
  let nextDate = new Date(date);
  return nextDate;
}

export const yearToDate = (date) => { //yyyy-mm-dd format
  let setDate = new Date(date)
  let dateFormat = [
      setDate.getFullYear(),
      ('0' + (setDate.getMonth() + 1)).slice(-2),
      ('0' + setDate.getDate()).slice(-2)
      ].join('-');
  return dateFormat;
}

export const sevenDays = (d) => {
  let date = new Date(d);
  date = date.setDate(date.getDate() + 8)
  let nextDate = new Date(date);
  return nextDate;
}

export const customStylesWhiteBg = {
  control: (provided, state) =>
    true
    ? {
        ...provided,
        background: "#ffff",
        boxShadow: "0 0 0 0.0000 red !important",
        borderColor: "red !important"
      }
    :
    provided
}

export const customStyles = {
  control: (provided, state) =>
    true
    ? {
        ...provided,
        background: "#f2f3f7",
        boxShadow: "0 0 0 0.0000 red !important",
        borderColor: "red !important"
      }
    :
    provided
}

export const customStylesSimpleWhiteBg = {
  control: (provided, state) =>
    true
    ? {
        ...provided,
        background: "#fff",
        borderColor: '#E4E6EF !important'
      }
    : {
      ...provided,
      background: "#fff",
      borderColor: '#E4E6EF !important'
    }
}

export const customStylesSimple = {
  control: (provided, state) =>
    true
    ? {
        ...provided,
        background: "#f2f3f7"
      }
    : {
      ...provided,
      background: "#f2f3f7",
    }
}

export const getDateAndTime = (dateTimestamp) => {
  let dt;
  if(dateTimestamp) {
    dt = new Date(dateTimestamp);
  } else {
    dt = new Date();
  }

  const year = dt.getUTCFullYear();
  let month = dt.getUTCMonth() + 1;
  let day = dt.getUTCDate();

  if(day < 10) {
    day = "0"+day;
  }
  if(month < 10) {
    month = "0"+month;
  }

  const date = day + '-' + month + '-' + year;

  let hours =  dt.getHours();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';

  hours = (hours % 12) || 12;

  let minutes = dt.getMinutes();
  let seconds = dt.getSeconds();

  if(minutes < 10) {
    minutes = "0"+minutes;
  }
  if(seconds < 10) {
    seconds = "0"+seconds;
  }

  let finalTime = hours + ":" + minutes + " " + AmOrPm;

  return date + ", " + finalTime;
}

export const getCurrentDate = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + '-' + mm + '-' + yyyy;

  return today;
}

export const getPreviousDate = (daysCount) => {
  const date = new Date();
  date.setDate(date.getDate() - daysCount);
  
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = date.getFullYear();

  return dd + '-' + mm + '-' + yyyy;
}
